import React from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import {DateUtils} from "../../../../actions/common_utils";
import {Card} from "react-bootstrap";
import {approveTask, fetchReportableActivityAsync, fetchTask, fetchTaskAsync, rejectTask} from "../../../../actions/task_actions";
import {downloadDocument, fetchDocumentsUploadedForTask, fetchDocumentsUploadedForTaskAsync} from "../../../../actions/doc_actions"; 
import BootstrapTable from "react-bootstrap-table-next";
import { createNewHireRequest,cancelClearanceCheck } from "../../../../actions/new_hire_actions";
import {SubjectTaskRCheckClearanceReview} from '../../dash/task/task_checkclearance_review'

class SubjectReportsCheckClearanceComponent extends React.Component {
    constructor(props) {
        super(props);
 
        this.state ={
             isShowClearanceInformation : false
        }
    }

    componentDidMount() {
        console.log('ID passed is ', this.props.match.params.id)
        this.props.fetchTask(this.props.match.params.id);
    }
    handleCancel = async() => {
        let {task} = this.props; 
        try {
           const response =  await cancelClearanceCheck([task.id]);
            this.props.showSuccessGrowl("Cancel Request", response.data);
        } catch (e) {
            console.log('EError rresp is ', e);
            if (e.response && e.response.status === 500){
                this.props.showErrorGrowl("Cancel Request", e.response.data);
            } else {
                this.props.showErrorGrowl("Unable to cancel Request", "Unexpected error");
            }
        }
        this.props.history.push("/org/dash/task");

    }
    onNewHire = async () => { 
        let {task} = this.props; 
        if(task && task.subjectHireDetailsUI){
            if (task.subjectHireDetailsUI?.requiredClearanceLevel) {
                task.subjectHireDetailsUI.requiredClearanceLevel = task?.subjectHireDetailsUI?.requiredClearanceLevel.id || '';
            }
            if(task.subjectHireDetailsUI?.citizenshipAcquiredType?.id){
                task.subjectHireDetailsUI.citizenshipAcquiredType = task.subjectHireDetailsUI?.citizenshipAcquiredType?.id
            }else{
                task.subjectHireDetailsUI.citizenshipAcquiredType = task.subjectHireDetailsUI?.citizenshipAcquiredType
            }
        console.log('New hires ', task.subjectHireDetailsUI)
        try {
            await createNewHireRequest([task.subjectHireDetailsUI]);
            this.props.showSuccessGrowl("New Hire Request", "Successfully created New Hire Request");
            this.props.history.push("/org/dash/task");
        } catch (e) {
            console.log(e);
            this.props.showErrorGrowl("Unable to initiate new Hire process", "Unexpected error");
        }
    }
    }

    handelClearInform = () =>{
        this.setState({ isShowClearanceInformation: true })

    }
  

    getColumns() {
        return [{
            dataField: "personFirstName",
            text: "First Name",
            formatter: (cell, row) => cell,
        }, {
            dataField: "personLastName",
            text: "Last Name",
            formatter: (cell, row) => cell,
        }, {
            dataField: "countryOfBirth",
            text: "Country of Birth",
            formatter: (cell, row) => cell,
        }, {
            dataField: "workEmailAddress",
            text: "Email Address",
            formatter: (cell, row) => cell,
        } ]
    }

    render() {

        let {task, docs} = this.props; 
        if (!task) {
            return <div>Loading...</div>;
        }
          console.log("Check Clearance Task Is. ", task );
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>Check Clearance Review
                    {(task.dateCompleted !== null) && <ButtonToolbar>
                    <Button variant="danger" type="button"  onClick={this.handelClearInform}>clearance information</Button>
                    </ButtonToolbar>}
                    </Card.Header>
                    <Card.Body>
                        <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={[task.subjectHireDetailsUI]}
                            columns={this.getColumns()}
                            onTableChange={this.onTableChange}
                            defaultSorted={[{ dataField: 'name', order: 'asc' }]} />
                           {this.state.isShowClearanceInformation ? <SubjectTaskRCheckClearanceReview isReported={true} task={task}/>:null}
                    </Card.Body>

                    <Card.Footer>
                            <ButtonToolbar>
                                <Button variant="danger" type="button" onClick={this.handleCancel}>Cancel</Button>
                                <Button variant="danger" type="button" onClick={this.onNewHire.bind(this)}>Initiate New Hire</Button>
                            </ButtonToolbar>
                    </Card.Footer>
                </Card>
            </React.Fragment>
        )
    }
}
function mapStateToProps({task, docs}) {
    return {
        task,
        docs
    }
}
export const
SubjectReportsCheckClearance = connect(mapStateToProps, { 
    fetchTask,fetchTaskAsync,
        showSuccessGrowl,
        showErrorGrowl,
        fetchDocumentsUploadedForTask,
        approveTask,
        rejectTask,
    })(SubjectReportsCheckClearanceComponent);

