import React, {Component, Fragment} from 'react';
import {fetchSubjectForUserCreation,} from "../../../../../actions/subject_actions";
import {Steps} from "primereact/steps";
import {connect} from "react-redux";
import {SubmissionError} from "redux-form";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import {ButtonToolbar, Card, Form, Modal} from 'react-bootstrap';
import {ROLES} from "../../../../../actions/auth_actions";
import {
    createUser,
    fetchOrgAdminAssignableRole,
    fetchUserName,
    updateUserRole
} from "../../../../../actions/user_actions";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {FieldRBInput, FieldRBSelectWithKeys} from "../../../../../components/redux_form/field_components_rb";
import Alert from "react-bootstrap/Alert";
import {FieldInvisibleInput} from "../../../../../components/redux_form/field_components_custom";
import {showSuccessGrowl, showErrorGrowl} from "../../../../../actions/msg_actions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import {CommonUtils} from "../../../../../actions/common_utils";
import {getActiveOrgContractsAsync} from "../../../../../actions/contract_actions";
import {FormikFieldDualListBox} from "../../../../../components/formik/formik_field_duallistbox";
import DeleteConfirmationModal from "../../../../../components/delete_confirm_modal";

class UserNewWizardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            showModal: false,
            selectedSubjectId: null,
            selectedSubject: null,
            page: 1,
            sizePerPage: 5,
            createAdminUser: false,
        }
    }


    componentDidMount() {
        this.fetchAvailRoleTypes();
        this.fetchAvailContracts();
        this.fetchSubjectsForUserCreation();
    }


    fetchAvailRoleTypes = async () => {
        let availRoles = await fetchOrgAdminAssignableRole(
            (error) => {
                this.props.showErrorGrowl("Cannot fetch roles available", error.message);
            }
        );
        this.setState({
            availRoles
        })
    }

    fetchSubjectsForUserCreation = async () => {
        let subjectsForUserCreation = await fetchSubjectForUserCreation(
            (error) => {
                this.props.showErrorGrowl("Cannot fetch subjects for user creation", error.message);
            }
        );
        this.setState({
            subjects: subjectsForUserCreation
        })
    }

    fetchAvailContracts = async () => {
        let availContractObjects = await getActiveOrgContractsAsync(
            (error) => {
                this.props.showErrorGrowl("Cannot fetch contracts available", error.message);
            }
        );
        let availContracts = [];
        availContractObjects.forEach((eachContract) => {
            availContracts.push({
                value: eachContract.id,
                label: eachContract.name
            })
        });

        this.setState({
            availContracts
        })
    }

    doesUserNameExists = () => {
        const {selectedSubject} = this.state;
        return (selectedSubject !== null && selectedSubject.userName !== null && selectedSubject.userName.length > 0);
    };

    getSteps = () => {
        let secondStepName = "Create User";
        if (this.doesUserNameExists()) {
            // Ok user name is already there
            secondStepName = "Assign Role";
        }
        const stepItems = [
            {label: "Select Subject"},
            {label: secondStepName},
        ];

        const onActiveIndexChange = (e) => this.setState({activeStep: e.index});
        return (
            <Steps model={stepItems} activeIndex={this.state.activeStep}
                   activeIndexChange={onActiveIndexChange.bind(this)} className="user-create-steps"/>
        )
    };

    hasSysAdmin() {
        const roles = sessionStorage.getItem(ROLES);
        return (roles && roles !== "undefined" && JSON.parse(roles).filter((role) => role.role === "SYS_ADMIN").length > 0) // Contains SYS_ADMIN role
    }

    isSelectedRoleContractRelated = (values) => {
        if (values.roleType.startsWith('CONTRACT')){
            return true;
        } else {
            return false;
        }
    }

    open = () => {
        this.setState({
            showModal: true,
            activeStep: 0,
            selectedSubjectId: null,
            selectedSubject: null,
            page: 1,
            sizePerPage: 5,
            userName: null
        })
    };

    close = () => {
        const {
            onFinish = () => {
            }
        } = this.props;
        this.setState({
            showModal: false,
            createAdminUser: false
        });

        onFinish();
    };

    onUserCreate = (values) => {
        values.personId = this.state.selectedSubjectId;
        values.adminUserCreation = this.state.createAdminUser;
        console.log('Create role ', values);
        return this.props.createUser(values, () => {
            // Success Response
            this.props.showSuccessGrowl("User Created", "User was created successfully");
            this.close();
        }, (message) => {
            // Failure Response
            throw new SubmissionError({
                _error: message
            });
        });
    }

    onAssignRole = (values) => {
        values.personId = this.state.selectedSubjectId;
        values.adminUserCreation = this.state.createAdminUser;
        values.userName = this.state.selectedSubject.userName;
        console.log('Assign role ', values);
        return this.props.updateUserRole(values, () => {
            // Success Response
            this.props.showSuccessGrowl("User Updated", "User was updated successfully");
            this.close();
        }, (message) => {
            // Failure Response
            throw new SubmissionError({
                _error: message
            });
        });
    }

    onTableChange(type, {page, sizePerPage}) {
        this.setState({
            page,
            sizePerPage
        })
    }

    previousPage = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    }

    renderStepContent = () => {
        switch (this.state.activeStep) {
            case 0:
                return this.getSubjectList();

            case 1:
                if (!this.doesUserNameExists()){
                    return this.userFormCreateUser();
                } else {
                    return this.userFormAssignRole();
                }
        }
    }

    getUserName = async (subject) => {
        console.log(subject);
        let uName = await fetchUserName(subject.id,
            (error) => {
                console.log('Error is ', error);
            }
        );
        if (uName && uName.userName) {
            this.setState({
                userName: uName.userName
            });
        }
    }

    userFormAssignRole() {
        const {availRoles} = this.state;
        console.log('Options for select is ', availRoles)
        return (
            <Formik
                initialValues={{
                    roleType: "ORG_ADMIN",
                    contracts: []
                }}
                enableReinitialize={true}
                onSubmit={this.onAssignRole}
                validationSchema={
                    Yup.object().shape({})
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur})=> (

                    <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>User {this.state.selectedSubject.userName} exists. Please assign role</Form.Label>
                                        <Form.Control required as="select" name={'roleType'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Select a Role'}
                                                      isInvalid={touched.roleType && !!errors.roleType}
                                                      value={values.roleType}
                                        >
                                            {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(availRoles))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.roleType}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {this.isSelectedRoleContractRelated(values) &&
                                    <Form.Group as={Col} md="8" controlId={'contracts'}>
                                        <Form.Label>Associated Contracts</Form.Label>
                                        <Field
                                            id="contracts"
                                            name="contracts"
                                            component={FormikFieldDualListBox}
                                            options={this.state.availContracts}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contracts}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    }
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <ButtonToolbar>
                                   
                                    <Button onClick={this.previousPage}>Previous</Button>
                                    <Button variant="primary" type="submit"
                                            disabled={isSubmitting}>Assign Role </Button>
                                             {/* <Button onClick={this.close}>Cancel</Button> */}
                                             <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Create User"
                                                  handleDeleteCallback={this.close}/>
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>

                    </Form>
                )}

            </Formik>
        );
    }

    userFormCreateUser() {
        const {handleSubmit, error} = this.props;
        const {availRoles, userName} = this.state;
        return (
            <Formik
                initialValues={{
                    roleType: "ORG_ADMIN",
                    userName: userName,
                    contracts: []
                }}
                enableReinitialize={true}
                onSubmit={this.onUserCreate}
                validationSchema={
                    Yup.object().shape({})
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur})=> (

                    <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
                        <Card className='border_none'>
                            {/* <Card.Header>
                               
                            </Card.Header> */}

                            <div className='display_error'>
                            {error && <Alert variant="danger">{error}</Alert>}
                            </div>
                           
                            <Card.Body className='border-top mt-20'>
                                <Row>
                                        <Form.Group as={Col} md="6" controlId={'userName'}>
                                            <Form.Label>User Name</Form.Label>
                                            <Form.Control required type="text" name={'userName'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'User Name'}
                                                          isInvalid={touched.userName && !!errors.userName}
                                                          value={values.userName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.userName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Control required as="select" name={'roleType'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          placeholder={'Select a Role'}
                                                          isInvalid={touched.roleType && !!errors.roleType}
                                                          value={values.roleType}
                                            >
                                                {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(availRoles))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.roleType}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="12">
                                        
                                        <Alert variant={'info'}>
                                            Password reset email link will be sent to the subject's email. Only
                                            username needs to be communicated to the subject
                                        </Alert>
                                    
                                        </Form.Group>
                                </Row>
                                <Row>
                                    {this.isSelectedRoleContractRelated(values) &&
                                    <Form.Group as={Col} md="12" controlId={'contracts'}>
                                        <Form.Label>Associated Contracts</Form.Label>
                                        <Field
                                            id="contracts"
                                            name="contracts"
                                            component={FormikFieldDualListBox}
                                            options={this.state.availContracts}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contracts}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    }
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <ButtonToolbar>
                                  
                                    <Button onClick={this.previousPage}>Previous</Button>
                                    <Button variant="primary" type="submit"
                                            disabled={isSubmitting}>Create</Button>
                                              {/* <Button onClick={this.close}>Cancel</Button> */}
                                              <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Create User"
                                                  handleDeleteCallback={this.close}/>
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>

                    </Form>
                )}

            </Formik>
        );
    }

    gotoNextPage = () => {
        if (this.state.activeStep === 0){
            this.getUserName(this.state.selectedSubject);
        }
        this.setState({
            activeStep: this.state.activeStep + 1
        })
    }

    getSubjectList() {
        const {sizePerPage, page, subjects} = this.state;
        const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            selected: [this.state.selectedSubjectId],
            onSelect: (row) => {

                this.setState({
                    selectedSubjectId: row.id,
                    selectedSubject: row,
                })
            }
        }
        const columns = [{
            dataField: 'personFirstName',
            text: 'First Name',
            // formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            // formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'socialSecurityNumber',
            text: 'SSN',
            // formatter: (cell, row) => <Link to={`/org/dash/subject/${row.id}`}>{cell}</Link>,
            sort: true,
            filter: textFilter()
        }];

        return (
            <Fragment>
                <Modal.Body>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                                    data={subjects}
                                    columns={columns} filter={filterFactory()} selectRow={selectRow}
                                    onTableChange={this.onTableChange}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.gotoNextPage}>Next</Button>
                    {/* <Button onClick={this.close}>Cancel</Button> */}
                    <DeleteConfirmationModal buttonText={"Cancel"}  actionText={"cancel"} deleteEntity="Create User"
                                                  handleDeleteCallback={this.close}/>
                </Modal.Footer>
            </Fragment>
        );
    }

    // pagination={paginationFactory({
    //                                   page,
    //                                   sizePerPage,
    //                                   totalSize: this.props.subjects.length,
    //                                   onPageChange : this.onPageChange,
    //                                   onSizePerPageChange: this.onSizePerPageChange
    //                               })}


    onSizePerPageChange = (newPageSize, newPage) => {
        this.setState({
            sizePerPage: newPageSize,
            page: newPage
        })
    }
    onPageChange = (newPageNo) => {
        this.setState({
            page: newPageNo
        })
    }

    render() {
        const {showModal} = this.state;

        return (
            <Fragment>
                <Button variant="primary" onClick={() => this.open()}>Create User</Button>{' '}
                <Modal show={showModal} onHide={this.close} size={'lg'} scrollable={true}>
                    {this.getSteps()}
                    {this.renderStepContent()}
                </Modal>
            </Fragment>
        )
    }
}

export const UserNewWizard = connect(null, {
        showSuccessGrowl,
        fetchSubjectForUserCreation,
        createUser,
        updateUserRole
    })(UserNewWizardComponent);

UserNewWizard.propTypes = {
    onFinish: PropTypes.func
};