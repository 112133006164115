import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {customFilter, multiSelectFilter, textFilter} from "react-bootstrap-table2-filter";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../../actions/session_constants";
import {fetchIssuesWithEntityForOrg, getIssuesForOrgWithFilter} from "../../../../actions/task_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import {Picky} from "react-picky";
import {ConditionalDisplay, isHROnlyRole, isHRRole} from "../../../util/shouldDisplay";
import {DateUtils} from "../../../../actions/common_utils";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Task_Filter } from "./task_filter";

class TaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            filter: {
                showManagedOrgsFilter: "",
                issueNameFilter: "",
                entityTypesFilter: [{value: "ORG", label: "Organization"}, {value: "SUBJECT", label: "Subject"}],
                entityNameFilter: "",
                issueStatusFilter: isHROnlyRole() ? [{value: "ACTIVE", label: "Active"},{value: "COMPLETED", label: "Completed"}] : [{value: "ACTIVE", label: "Active"}],
                includeSubOrgs:false,
                docSubmitWorkflowStatus: [{value: "PENDING", label: "Pending"},
                {value: "SUBJECT_NOTIFIED", label: "Subject Notified"},
                {value: "DOCS_SUBMITTED", label: "Documents Submitted"},
                {value: "REJECTED", label: "Rejected"},
                {value: "CLEARANCE_CHECK_COMPLETED", label: "Clearance Check Completed"}]
            },
            totalSize: 0,
            page: 1,
            sizePerPage: 10,
            loading: true,
            selEntityTypes: [{value: "ORG", label: "Organization"}, {value: "SUBJECT", label: "Subject"}],
            selStatus: [{value: "ACTIVE", label: "Active"}],
            selWFStatus: [{value: "PENDING", label: "Pending"},
                {value: "SUBJECT_NOTIFIED", label: "Subject Notified"},
                {value: "DOCS_SUBMITTED", label: "Documents Submitted"},
                {value: "REJECTED", label: "Rejected"},
                {value: "CLEARANCE_CHECK_COMPLETED", label: "Clearance Check Completed"}],
            selShowManagedOrgs: false
        }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.type){
            switch (this.props.match.params.type){
                case "FACILITY":
                    this.setState({
                        selEntityTypes: [{value: "FACILITY", label: "Location"}]
                    });
                    break;
                case "CONTRACT":
                    this.setState({
                        selEntityTypes: [{value: "CONTRACT", label: "Contract"}]
                    });
                    break;
                case "SUBJECT":
                    this.setState({
                        selEntityTypes: [{value: "SUBJECT", label: "Subject"}]
                    });
                    break;
                default:
                    this.setState({
                        selEntityTypes: [{value: "ORG", label: "Organization"}]
                    });
                    break;
            }
        } else {
            this.setState({
                selEntityTypes: [{value: "ORG", label: "Organization"}, {value: "SUBJECT", label: "Subject"}]
            });
        }




    }

    setFilterValue = (filter, defaultVal = "") => filter ? filter.filterVal : defaultVal;

    setSelectFilterValue = (filter, defaultVal = []) => filter ? filter.filterVal.map((val) => val.value) : defaultVal;

    setFilterValueNew = (filter, defaultVal = "") => filter ? filter : defaultVal;

    setSelectFilterValueNew = (filter, defaultVal = []) => filter ? filter.map((val) => val.value) : defaultVal;

    onTableChange = async (type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) => {
        console.log(type);
        // Filters
        let {filter}=this.state;
        // const showManagedOrgsFilter = this.setFilterValue(filters.orgName, false);
        // const issueNameFilter = this.setFilterValue(filters.issueName);
        // const entityTypesFilter = this.setSelectFilterValue(filters.entityType, ["ORG", "SUBJECT"]);
        // const entityNameFilter = this.setFilterValue(filters.entityName);
        // const issueStatusFilter = this.setSelectFilterValue(filters.statusString, ["ACTIVE"]);
        // const docSubmitWorkflowStatus = this.setSelectFilterValue(filters.docSubmitWorkflowStatus, ["PENDING", "SUBJECT_NOTIFIED", "DOCS_SUBMITTED", "REJECTED"]);
        const showManagedOrgsFilter = this.setFilterValueNew(filter.includeSubOrgs, false);
        const issueNameFilter = this.setFilterValueNew(filter.issueNameFilter);
        const entityTypesFilter = this.setSelectFilterValueNew(filter.entityTypesFilter, ["ORG", "SUBJECT"]);
        const entityNameFilter = this.setFilterValueNew(filter.entityNameFilter);
        const issueStatusFilter = this.setSelectFilterValueNew(filter.issueStatusFilter, ["ACTIVE"]);
        const docSubmitWorkflowStatus = this.setSelectFilterValueNew(filter.docSubmitWorkflowStatus, ["PENDING", "SUBJECT_NOTIFIED", "DOCS_SUBMITTED", "REJECTED"]);

        // Sort
        let issueNameSort = 0;
        let dateCreatedSort = 0;
        let orgNameSort = 0;
        let entityTypeSort = 0;
        let entityNameSort = 0;
        let docSubmitWorkflowStatusSort = 0;
        let issueStatusSort = 0;

        const sortDir = sortOrder === 'asc' ? 1 : -1;

        switch (sortField) {
            case "issueName":
                issueNameSort = sortDir;
                break;
            case "dateCreated":
                dateCreatedSort = sortDir;
                break;
            case "orgName":
                orgNameSort = sortDir;
                break;
            case "entityType":
                entityTypeSort = sortDir;
                break;
            case "entityName":
                entityNameSort = sortDir;
                break;
            case "docSubmitWorkflowStatus":
                docSubmitWorkflowStatusSort = sortDir;
                break;
            case "active":
                issueStatusSort = sortDir;
                break;
        }

        this.setState({
            loading: true
        });

        const response = await getIssuesForOrgWithFilter(issueNameFilter, issueNameSort,
            dateCreatedSort,
            entityNameFilter, entityNameSort,
            entityTypesFilter, entityTypeSort,
            issueStatusFilter, issueStatusSort,
            docSubmitWorkflowStatus, docSubmitWorkflowStatusSort,
            showManagedOrgsFilter, orgNameSort,
            page - 1, sizePerPage);
            console.log(response)
        this.setState({
            tasks: response.data,
            totalSize: response.totalSize,
            page: page,
            sizePerPage: sizePerPage,
            loading: false
        });
    }

    getCellLink = (row, cellData) => { 
        if (row.entityType && row.entityType.id === 'SUBJECT'){
            if (isHROnlyRole()){
                if (row.issueType && row.issueType.id === "CLEARANCE_CHECK") {
                    return (<Link to={`/org/dash/task/reviewCheckClearancebyHR/${row.issueId}`}>{cellData}</Link>);
                }
                return cellData;
            }
            if (row.issueType && row.issueType.id === 'NEW_HIRE'){
                return (<Link to={`/org/dash/newHireTask/review/${row.issueId}`}>{cellData}</Link>);
            }else if (row.issueType && row.issueType.id === "CLEARANCE_CHECK") {
                return (<Link to={`/org/dash/task/reviewCheckClearance/${row.issueId}`}>{cellData}</Link>);
            }  else if (row.docSubmitWorkflowStatus){
                return (<Link to={`/org/dash/task/review/${row.issueId}`}>{cellData}</Link>);
            } else {
                return (<Link to={`/org/dash/task/${row.issueId}`}>{cellData}</Link>);
            }
        } else {
            if (row.issueName && row.issueName.startsWith("Reported By Others")) {
                return (<Link to={`/org/dash/task/reviewReportsByOthers/${row.issueId}`}>{cellData}</Link>);
            } else if (row.issueType && row.issueType.id === "CLEARANCE_CHECK") {
                return (<Link to={`/org/dash/task/reviewCheckClearance/${row.issueId}`}>{cellData}</Link>);
            } else {
                return (
                    <Link to={`/org/dash/task/${row.issueId}`}>{cellData}</Link>
                );
            }
        }
    }

    getColumns() {
        const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
            return (
                <div style={ { display: 'flex', flexDirection: 'column' } }>
                    <div>
                        { column.text }
                        { sortElement }
                    </div>
                    { filterElement }
                </div>
            );
        }

        return [{
            dataField: 'issueName',
            text: 'Name',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            //filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'dateCreated',
            text: 'Date Created',
            formatter: (cell, row) => this.getCellLink(row, (cell? DateUtils.getDateTimeString(cell): cell)),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'orgName',
            text: "Organization",
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'entityType',
            text: 'Entity Type',
            formatter: (cell, row) => this.getCellLink(row,cell && cell.displayName),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'entityName',
            text: 'Entity Name',
            formatter: (cell, row) => this.getCellLink(row, cell),
            sort: true,
            // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'docSubmitWorkflowStatus',
            text: 'Workflow Status',
            formatter: (cell, row) => this.getCellLink(row, cell && cell.displayName),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: 'statusString',
            text: 'Status',
            // formatter: (cell, row) => this.getCellLink(row, cell ? "Active" : "Completed"),
            formatter: (cell, row) => this.getCellLink(row, cell),// this.getStatusString(row)),
            sort: true,
            headerFormatter: headerFormatter
        }];
    }
    onFilterClick = async (value,Reset) => {
        console.log("Inside main", value);
        if (Reset) {
            value.includeSubOrgs = "",
                value.issueNameFilter = "",
                value.entityTypesFilter = [{ value: "ORG", label: "Organization" }, { value: "SUBJECT", label: "Subject" }],
                value.entityNameFilter = "",
                value.issueStatusFilter = [{ value: "ACTIVE", label: "Active" }],
                value.docSubmitWorkflowStatus = [{ value: "PENDING", label: "Pending" },
                { value: "SUBJECT_NOTIFIED", label: "Subject Notified" },
                { value: "DOCS_SUBMITTED", label: "Documents Submitted" },
                { value: "REJECTED", label: "Rejected" }]
        }
        const showManagedOrgsFilter = this.setFilterValueNew(value.includeSubOrgs, false);
        const issueNameFilter = this.setFilterValueNew(value.issueNameFilter);
        const entityTypesFilter = this.setSelectFilterValueNew(value.entityTypesFilter, ["ORG", "SUBJECT"]);
        const entityNameFilter = this.setFilterValueNew(value.entityNameFilter);
        const issueStatusFilter = this.setSelectFilterValueNew(value.issueStatusFilter, ["ACTIVE"]);
        const docSubmitWorkflowStatus = this.setSelectFilterValueNew(value.docSubmitWorkflowStatus, ["PENDING", "SUBJECT_NOTIFIED", "DOCS_SUBMITTED", "REJECTED"]);


        let { page, sizePerPage } = this.state;
        this.setState({
            loading: true
        });
        let issueNameSort = 1;
        let dateCreatedSort = 0;
        let entityNameSort = 0;
        let entityTypeSort = 0;
        let issueStatusSort = 0;
        let docSubmitWorkflowStatusSort = 0;
        let orgNameSort = 0;

        const response = await getIssuesForOrgWithFilter(issueNameFilter, issueNameSort,
            dateCreatedSort,
            entityNameFilter, entityNameSort,
            entityTypesFilter, entityTypeSort,
            issueStatusFilter, issueStatusSort,
            docSubmitWorkflowStatus, docSubmitWorkflowStatusSort,
            showManagedOrgsFilter, orgNameSort,
            0, sizePerPage);
        console.log(response)
        this.setState({
            tasks: response.data,
            totalSize: response.totalSize,
            page: 1,
            sizePerPage: sizePerPage,
            loading: false,
            filter:value
        });
    }   
    // getBreadCrumb() {
    //     console.log('this.props are ', this.props);
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Tasks
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    render() {
        const {page, sizePerPage, tasks = [], totalSize, loading,filter} = this.state;

        const remoteSettings = {
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false
        };

        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}
                

                {ConditionalDisplay(
                    <div className="float-right">
                        <ButtonToolbar>
                            <Link className="btn btn-primary" to="/org/dash/task/new">New Task</Link>
                            <Link className="btn btn-primary" to="/org/dash/newHireTask/new">New Hire Task</Link>
                            {/*<Link className="btn btn-primary" to="/org/dash/newHireClearanceCheckTask/new">Clearance Check</Link>*/}
                        </ButtonToolbar>
                    </div>
                )}
                {isHROnlyRole() &&
                <div className="float-right">
                    <ButtonToolbar>
                        <Link className="btn btn-primary" to="/org/dash/newHireTask/new">New Hire Task</Link>
                        <Link className="btn btn-primary" to="/org/dash/newHireClearanceCheckTask/new">Clearance Check</Link>
                    </ButtonToolbar>
                </div>
                }

                <div className="d-flex">
                <h3 className="page_title_left">Tasks</h3>
                <Task_Filter  filters={filter} onSubmit={this.onFilterClick} />
                </div>
                
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="issueId" data={tasks}
                                columns={this.getColumns()} filter={filterFactory()} remote={remoteSettings}
                                filterPosition={"top"}
                                onTableChange={this.onTableChange}
                                loading={loading}
                                pagination={paginationFactory({page, sizePerPage, totalSize: totalSize})}
                                defaultSorted={[{dataField:'dateCreated', order:'desc'}]}/>
            </Fragment>
        )
    }
}

function mapStateToProps({cur_org}) {
    return {
        cur_org: cur_org,
    }
}

export default connect(mapStateToProps, null)(TaskList);