import React, {Component, Fragment} from "react";
import {Form, Card, Col, Button, ButtonToolbar, Container, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {getDocTypes, getEntityTypes} from "../../../../actions/fieldValue_actions";
import {connect} from "react-redux";
import {CUR_ORG} from "../../../../actions/session_constants";
import {ORG_NAME} from "../../../../actions/auth_actions";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import * as Yup from "yup";
import {
    createEmailTask,
    createEmailTaskPrecheck,
    fetchTaskAsync,
    updateEmailTask
} from "../../../../actions/task_actions";
import {showSuccessGrowl, showErrorGrowl} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {getOrgContracts} from "../../../../actions/contract_actions";
import {getActiveOrgPersons, getOrgPersons} from "../../../../actions/subject_actions";
import {getOrgFacilities} from "../../../../actions/facility_actions";
import {fetchTemplates} from "../../../../actions/signature_actions";
import {Formik, Field} from "formik";
import {CommonUtils} from "../../../../actions/common_utils";
import {FormikDatePickerComponent} from "../../../../components/formik/formik_date_picker";
import {FormikFieldDropzone} from "../../../../components/formik/formik_field_dropzone";
import {FormikFieldDualListBox} from "../../../../components/formik/formik_field_duallistbox";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {fetchAsset} from "../../../../actions/asset_actions";
import PropTypes from "prop-types";
import {ClearanceDebrief} from "../subject/clearance/subject_addl_clearance_debrief";

class TaskUpdateComp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availDocTypes: [],
            showModal: false,
            formValues: null,
            warningMessage: null,
        }
    }

    componentDidMount() {
        getDocTypes((response) => {
            // let documentTypes = [];
            // response.data.forEach((docType) => {
            //     documentTypes.push({
            //         value: docType.id,
            //         label: docType.type
            //     })
            // });
            this.setState({
                availSubjectDocTypes: response.data
            })
        });
    }

    onSubmit = async (values) => {
        if (!Array.isArray(values.requiredDocs)) { // When user clicks No on the confirmation and comes back the requiredDocs si already an array
            values.requiredDocs = [values.requiredDocs];
        }
        values.entityType = null;
        values.issueType = null;
        values.emailStatus = null;
        if (true){
            console.log('Form values ', values)
        }
        try {
            await updateEmailTask(values);
            this.props.showSuccessGrowl("Task updated successfully");
            this.close();
            // this.props.onFinish();
        } catch (error){
            console.log('Error updating task', error);
            this.props.showErrorGrowl("Unexpected error occurred", "Error updating task.");
        }

    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/task">
    //                 Tasks
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Update Task
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    open = () => {
        this.setState({showModal: true});
    }

    close = () => {
        this.setState({showModal: false, warningMessage: null});
    }

    handleCancel = () => {
        window.history.back();
    }

    render() {
        const {warningMessage, showModal} = this.state;
        const {task} = this.props;

        let newObject = {...task};
        if (task && task.requiredDocs && task.requiredDocs.length === 1 && task.requiredDocs[0].id) {
            newObject.requiredDocs = task.requiredDocs[0].id;
        }
        return (
            <React.Fragment>
                <Button onClick={this.open} variant="primary">Update Task</Button>
                <Modal size="lg" show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Task</Modal.Title>
                    </Modal.Header>
            <Formik
                initialValues={{...newObject}}
                enableReinitialize={true}
                onSubmit={this.onSubmit}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string().required('Enter a name'),
                        description: Yup.string().required('Please enter a description'),
                    })
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {/* <h3>New Task</h3> */}
                            <Card>
                                <Card.Header>Task Update Form</Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId={'name'}>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control required type="text" name={'name'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter the name'}
                                                          isInvalid={touched.name && !!errors.name}
                                                          value={values.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId={'dueDate'}>
                                            <Form.Label>Due Date</Form.Label>
                                            <Field
                                                disabled={true}
                                                id="dueDate"
                                                name="dueDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Due date"}
                                            />
                                        </Form.Group>

                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId={'requiredDocs'}>
                                            <Form.Label>Required Documents</Form.Label>
                                            <Form.Control required as="select" name={'requiredDocs'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={touched.requiredDocs && !!errors.requiredDocs}
                                                          value={values.requiredDocs}>
                                                {CommonUtils.getOptionsForSelect(this.state.availSubjectDocTypes)}

                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.requiredDocs}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId={'description'}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control required type="text" name={'description'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Enter Description'}
                                                          isInvalid={touched.description && !!errors.description}
                                                          value={values.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Fragment>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Submit</Button>
                                            {/* <Link to="/org/dash/task" className="btn btn-danger">Cancel</Link> */}
                                            <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Task Update Form"
                                                         handleDeleteCallback={this.handleCancel}/>
                                        </ButtonToolbar>
                                    </Fragment>
                                </Card.Body>
                            </Card>
                    </Form>
                )}
            </Formik>
                </Modal>
            </React.Fragment>);
    }
}

export const TaskUpdate = connect(null, {showSuccessGrowl, showErrorGrowl})(TaskUpdateComp);

TaskUpdate.propTypes = {
    task: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired
}