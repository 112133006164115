/**
 * Created by pshivaraman on 7/27/18.
 */

import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {fetchCurOrg} from "../../../../actions/org_actions";
import {CUR_ORG} from "../../../../actions/session_constants";
import {USER_ID} from "../../../../actions/auth_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import {deleteUserWithinOrg, fetchUsersByOrg} from "../../../../actions/user_actions";
import UserRoleModal from "../../../../components/user/user_role_modal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {UserNewWizard} from "./user_new_wizard/user_new";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import UserPasswordResetModal from "../../../../components/user/user_password_reset_modal";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {showSuccessGrowl} from "../../../../actions/msg_actions";
import {Button} from "react-bootstrap";
import { User_Filter } from "./user_filter";

class OrgUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {userName: "", personFirstName: "", personLastName: "", role: ""}
        }

        this.onUserCreate = this.onUserCreate.bind(this);
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Users
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    onUserCreate() {
        this.props.fetchUsersByOrg();
    }

    onUserRemove = async (userId) => {
        await deleteUserWithinOrg(userId);
        this.props.fetchUsersByOrg();
        this.props.showSuccessGrowl("User Removed Successfully");
    }

    getRole(row) {
        return row.roles ? row.roles.find(role =>
            role.owningEntityId === sessionStorage.getItem(CUR_ORG) ||
            (role.entityIds && role.entityIds.includes(sessionStorage.getItem(CUR_ORG)))
        ) : null; 
    }

    /**
     Decides if the user logged in and the one displayed are the same. If so they cannot change their role
     */
    displayAction(row) {
        if (row.id === sessionStorage.getItem(USER_ID)) {
            // logged in user cannot change their roles
            return false;
        } else {
            return true;
        }
    }

    componentDidMount() {
        this.props.fetchUsersByOrg();
    }

    onFilterClick = async (values) => {
        console.log(values)
        this.setState({
            filter: values
        })
    }
    userFilterby = () => { 
        let { filter } = this.state;  
        let {users} = this.props;

        let filteredData = users.filter(item =>
            (filter.userName === "" || item.userName.toLowerCase().includes(filter.userName.toLowerCase())) &&
            (filter.personFirstName === "" || (item.subject && String(item.subject.personFirstName).toLowerCase().includes(filter.personFirstName.toLowerCase()))) &&
            (filter.personLastName === "" || (item.subject && String(item.subject.personLastName).toLowerCase().includes(filter.personLastName.toLowerCase()))) &&
            (filter.role === "" || (String(this.getRole(item).role).toLowerCase().includes(filter.role.toLowerCase())))
            
        )
        return filteredData; 
    }

    render() {

        let {filter} = this.state;
        const {users} = this.props;
        console.log(users);

        const columns = [
            {
                dataField: "userName",
                text: "Username",
                formatter: (cell, row) => cell,
                sort: true,
                // filter: textFilter()
            },
            {
                dataField: "subject.personFirstName",
                text: "First Name",
                formatter: (cell, row) => cell,
                sort: true,
                // filter: textFilter()
            },
            {
                dataField: "subject.personLastName",
                text: "Last Name",
                formatter: (cell, row) => cell,
                sort: true,
                // filter: textFilter()
            },
            {
                dataField: "role",
                text: "Role",
                formatter: (cell, row) => {
                    const role = this.getRole(row);
                    return role ? role.role : "None";
                }
                ,
                sort: true,
                // filter: textFilter()
            },
            {
                dataField: "actions",
                text: "Actions",
                formatter: (cell, row) => {
                    if (this.displayAction(row)) {
                        const role = this.getRole(row);
                        let displayUserInfo = 'User';
                        if (row.subject){
                            displayUserInfo =  displayUserInfo + ' ' + row.subject.personFirstName + ' ' + row.subject.personLastName;
                        }
                        return (
                            <ButtonToolbar className="p-0 mb-0" style={{flexWrap: "nowrap"}}>
                                <UserPasswordResetModal user={row}/>
                                <UserRoleModal user={row} role={role ? role.role : "NONE"}
                                               onRoleAssigned={this.onUserCreate}/>
                                <DeleteConfirmationModal actionText={"Remove"} buttonType={'danger'}
                                                         buttonText={"Remove"}
                                                         deleteEntity={displayUserInfo}
                                                         handleDeleteCallback={() => this.onUserRemove(row.id)}
                                                         customButton={(onClickAction) =>
                                                             <Button onClick={onClickAction} variant={"link"} className={"close"}>
                                                                 <span aria-hidden="true" title={"Remove User"} aria-label="Remove User" >
                                                                    <i className="fa fa-trash"/>
                                                                 </span>
                                                             </Button>
                                                         }
                                />
                            </ButtonToolbar>
                        );
                    }
                }
            }
        ];

        return (
            <div>
                {/* {this.getBreadCrumb()} */}
                <div className="float-right">
                    <ButtonToolbar>
                        <UserNewWizard sysAdminUserCreation={false} onFinish={this.onUserCreate}/>
                    </ButtonToolbar>
                </div>
                {/* <h3 className="page_title_left">Users</h3> */}
                <div className="d-flex">
                    <h3 className="page_title_left">Users</h3>
                    <User_Filter filters={filter} onSubmit={this.onFilterClick} />
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="userName" data={this.userFilterby()}
                                columns={columns}
                                filter={filterFactory()}/>
            </div>
        )
    }
}

function mapStateToProps({users}) {
    return {
        users
    };
}

export default connect(mapStateToProps, {fetchUsersByOrg, showSuccessGrowl})(OrgUserList);
