/**
 * Created by pshivaraman on 6/27/18.
 */

import React, { Component, Fragment } from "react";
import {
  Form,
  Card,
  Col,
  Button,
  ButtonToolbar,
  Container,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getDocTypes,
  getEntityTypes,
} from "../../../../actions/fieldValue_actions";
import { connect } from "react-redux";
import { CUR_ORG } from "../../../../actions/session_constants";
import { ORG_NAME } from "../../../../actions/auth_actions";
import { fetchCurOrg } from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import * as Yup from "yup";
import {
  createEmailTask,
  createEmailTaskPrecheck,
} from "../../../../actions/task_actions";
import {
  showSuccessGrowl,
  showErrorGrowl,
} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { getOrgContracts } from "../../../../actions/contract_actions";
import {
  fetchActivePersonsByActiveOrgAsync,
  getActiveOrgPersons,
  fetchSubjectsByContractIdAndActiveFor,
  fetchActiveSubjectsByContractIdAsync,
  fetchSubjectsByFacilityIdAsync,
} from "../../../../actions/subject_actions";
import { getOrgFacilities } from "../../../../actions/facility_actions";
import { fetchTemplates } from "../../../../actions/signature_actions";
import { Formik, Field } from "formik";
import { CommonUtils } from "../../../../actions/common_utils";
import { FormikDatePickerComponent } from "../../../../components/formik/formik_date_picker";
import { FormikFieldDropzone } from "../../../../components/formik/formik_field_dropzone";
import { FormikFieldDualListBox } from "../../../../components/formik/formik_field_duallistbox";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";

class TaskNew extends Component {
  constructor(props) {
    super(props);

    this.onEntityTypeChange = this.onEntityTypeChange.bind(this);
    this.onMassEmailChange = this.onMassEmailChange.bind(this);

    this.state = {
      availDocTypes: [],
      availEntityTypes: [
        {
          id: "",
          name: "Select Entity Type...",
        },
      ],
      massEmailOptions: [
        {
          id: "no",
          name: "No. (User needs to take action to this task)",
        },
        {
          id: "yes",
          name: "Yes. (No feedback from user necessary)",
        },
      ],
      workflowOptions: [
        {
          id: "no",
          name: "No. (No workflow needed)",
        },
        {
          id: "yes",
          name: "Yes. (Yes, admin needs to approve the document(s))",
        },
      ],
      availEntities: [
        {
          id: "",
          name: "Select Assignee",
        },
      ],
      availSecondEntities: [
        {
          id: "",
          name: "Select Approver",
        },
      ],
      showModal: false,
      templateSelectedRoles: [],
      signatureSelected: false,
      firstSelected: false,
    };
  }

  getTemplates = async () => {
    let templates = await fetchTemplates((error) => {
      if (error.response.status === 412) {
        this.props.showErrorGrowl(
          "Emailing to subjects not allowed",
          "Organization does not allow email to be sent to subjects, so templates cannot be used"
        );
      } else {
        this.props.showErrorGrowl("Server Error", "A server error occurred!");
        console.log("Error is ", error);
      }
    });
    console.log("Templates are ", templates);
    if (templates && templates.length > 0) {
      templates = [
        {
          id: "",
          name: "Select Template...",
          roles: [],
        },
        ...templates,
      ];
    }
    this.setState({ templates: templates });
  };

  trimmSubjectFieldsAndAdd(subjects) {
    let trimmedFieldList = subjects.map((subject) => {
      return {
        value: subject.id,
        label: `${subject.personFirstName} ${subject.personLastName} (${subject.socialSecurityNumber})`,
      };
    });
    this.setState({ availSubjects: trimmedFieldList });
  }

  componentDidMount() {
    this.getTemplates();

    getDocTypes((response) => {
      let documentTypes = [];
      response.data.forEach((docType) => {
        documentTypes.push({
          value: docType.id,
          label: docType.type,
        });
      });
      this.setState({
        availSubjectDocTypes: documentTypes,
      });
    });

    getEntityTypes().then((response) => {
      let newEntityTypes = [
        {
          id: "",
          name: "Select Entity Type...",
        },
      ];
      response.data.forEach((entityType) => {
        console.log("EntityType is ", entityType);
        if (entityType.displayName.toLowerCase() !== "user") {
          newEntityTypes.push({
            id: entityType.id,
            name: entityType.displayName,
          });
        }
      });
      this.setState({
        availEntityTypes: newEntityTypes,
        selectableEntityTypes: newEntityTypes,
        formValues: null,
        warningMessage: null,
        showModal: false,
      });
    });

    let availSecondEntities = [
      {
        id: "",
        name: "Select Approver",
      },
    ];
    getActiveOrgPersons((response) => {
      let newEntityIds = [
        {
          id: "",
          name: "Second Entity Type",
        },
      ];
      response.data.forEach((subject) => {
        newEntityIds.push({
          id: subject.id,
          name: `${subject.personFirstName ? subject.personFirstName : ""} ${
            subject.personLastName
          }`,
        });
      });

      for (let i = 1; i < newEntityIds.length; i++) {
        if (newEntityIds[i].id !== event.target.value) {
          availSecondEntities.push({
            id: newEntityIds[i].id,
            name: newEntityIds[i].name,
          });
        }
      }
      this.setState({
        availSecondEntities: availSecondEntities,
      });
    });
  }

  onConfirm = () => {
    let { formValues } = this.state;
    this.props.createEmailTask(
      formValues,
      (response) => {
        if (response.status === 503) {
          this.props.showSuccessGrowl(response.data);
        } else {
          this.props.showSuccessGrowl("Task Created Successfully!");
        }
        this.props.history.push(
          "/org/dash/task/filter/" + formValues.entityType
        );
      },
      (error) => {
        this.props.showErrorGrowl(error.response.data);
        this.setState({
          warningMessage: null,
          showModal: false,
        });
      }
    );
  };

  onSubmit = async (values) => {
    if (!Array.isArray(values.requiredDocs)) {
      // When user clicks No on the confirmation and comes back the requiredDocs si already an array
      values.requiredDocs = [values.requiredDocs];
    }
    if (
      values.massEmailIssue &&
      values.massEmailIssue.toUpperCase() === "YES"
    ) {
      values.massEmailIssue = true;
    } else {
      values.massEmailIssue = false;
    }
    if (
      values.workflowRequired &&
      (values.workflowRequired === true ||
        values.workflowRequired.toUpperCase() === "YES")
    ) {
      values.workflowRequired = true;
    } else {
      values.workflowRequired = false;
    }
    // Clearing second entity ID if not required
    if (values.secondEntityId === "") {
      values.secondEntityId = null;
    }

    this.setState({ formValues: values });
    await this.props.createEmailTaskPrecheck(
      values,
      () => {
        this.onConfirm();
      },
      (error) => {
        if (error.response.status === 208 || error.response.status === 412) {
          console.log(error.response);
          this.setState({
            warningMessage: error.response.data,
            showModal: true,
          });
        } else {
          console.log("Error is ", error);
          this.props.showErrorGrowl(
            "Unexpected error occurred",
            "Error creating task."
          );
        }
      }
    );
  };

  onMassEmailChange(event) {
    if (event.target.value.toLowerCase() === "no") {
      this.setState({
        massEmail: false,
      });
    } else {
      this.setState({
        massEmail: true,
      });
    }
  }

  onWorkflowOptionChange = (event, setFieldValue) => {
    let { availEntityTypes } = this.state;
    if (event.target.value === "no") {
      this.setState({
        selectableEntityTypes: availEntityTypes,
      });
    } else {
      this.setState({
        selectableEntityTypes: this.getAvailEntityTypesToSubject(
          availEntityTypes,
          setFieldValue
        ),
      });
    }
  };

  getAvailEntityTypesToSubject(availEntityTypes, setFieldValue) {
    let newAvailTypes = [];
    availEntityTypes.forEach((entityType) => {
      if (entityType.id === "SUBJECT") {
        newAvailTypes.push(entityType);
      }
    });
    setFieldValue("entityType", "SUBJECT");
    this.getSubjects(setFieldValue);
    return newAvailTypes;
  }

  onTemplateChange(event, setFieldValue) {
    let { templates, availEntityTypes } = this.state;

    let roles = [];
    let newAvailTypes = availEntityTypes;
    if (event.target.value) {
      for (let template of templates) {
        if (template.id === event.target.value) {
          roles = template.roles;
          break;
        }
      }
      // newAvailTypes = this.getAvailEntityTypesToSubject(availEntityTypes, setFieldValue);
    }
    this.setState({
      templateSelectedRoles: roles,
      // "selectableEntityTypes": newAvailTypes
    });
  }

  secondSigOptions(event, templateId, entityType) {
    console.log("Val is ", event.target.value);
    debugger;
    if (event.target.value && templateId) {
      this.setState({
        firstSelected: true,
      });
    }
    // Depending on the entity id and type get subjects for selection
    switch (entityType) {
      case "CONTRACT":
        fetchActiveSubjectsByContractIdAsync(event.target.value).then(
          (subjects) => {
            this.trimmSubjectFieldsAndAdd(subjects);
          }
        );
        break;
      case "FACILITY":
        fetchSubjectsByFacilityIdAsync(event.target.value).then((subjects) => {
          this.trimmSubjectFieldsAndAdd(subjects);
        });
        break;
    }
    this.setState({ firstSelected: false });
  }

  onEntityTypeChange(event, setFieldValue) {
    this.setState({ firstSelected: false });
    switch (event.target.value) {
      case "ORG":
        // Add only the current organization to the list
        this.setState({
          availEntities: [
            // {
            //     id: "",
            //     name: "Current Organization"
            // },
            {
              id: sessionStorage.getItem(CUR_ORG),
              name: sessionStorage.getItem(ORG_NAME),
            },
          ],
        });
        this.secondSigOptions(event);

        fetchActivePersonsByActiveOrgAsync().then((subjects) => {
          this.trimmSubjectFieldsAndAdd(subjects);
          setFieldValue("entityId", sessionStorage.getItem(CUR_ORG));
        });

        break;
      case "":
        this.setState({
          availEntities: [
            {
              id: "",
              name: "Select an Entity Type...",
            },
          ],
          availSubjects: [],
        });
        setFieldValue("entityId", "");
        break;
      case "CONTRACT":
        getOrgContracts(sessionStorage.getItem(CUR_ORG)).then((response) => {
          let newEntityIds = [
            {
              id: "",
              name: "Select Contract",
            },
          ];
          response.data.forEach((contract) => {
            newEntityIds.push({
              id: contract.contract.id,
              name: contract.contract.name,
            });
          });

          this.setState({
            availEntities: newEntityIds,
            availSubjects: [],
          });
          setFieldValue("entityId", "");
        });
        break;
      case "SUBJECT":
        this.getSubjects(setFieldValue);
        break;
      case "FACILITY":
        getOrgFacilities(sessionStorage.getItem(CUR_ORG)).then((response) => {
          let newEntityIds = [
            {
              id: "",
              name: "Select Location",
            },
          ];
          response.data.forEach((facility) => {
            newEntityIds.push({
              id: facility.facility.id,
              name: facility.facility.name,
            });
          });

          this.setState({
            availEntities: newEntityIds,
            showEntityBox: true,
            availSubjects: [],
          });
          setFieldValue("entityId", "");
        });
        break;
    }
  }

  getSubjects(setFieldValue) {
    getActiveOrgPersons((response) => {
      let newEntityIds = [
        {
          id: "",
          name: "Select Subject",
        },
      ];
      response.data.forEach((subject) => {
        newEntityIds.push({
          id: subject.id,
          name: `${subject.personFirstName ? subject.personFirstName : ""} ${
            subject.personLastName
          }`,
        });
      });

      this.setState({
        availEntities: newEntityIds,
        availSubjects: [],
      });
      setFieldValue("entityId", "");
    });
  }

  // getBreadCrumb() {
  //   return (
  //     <Breadcrumb>
  //       <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
  //         Organization {this.props.cur_org.name}
  //       </BreadcrumbLink>
  //       <BreadcrumbLink to="/org/dash/task">Tasks</BreadcrumbLink>
  //       <Breadcrumb.Item active>New Task</Breadcrumb.Item>
  //     </Breadcrumb>
  //   );
  // }

  close = () => {
    this.setState({ showModal: false, warningMessage: null });
  };

  handleCancel = () => {
    window.history.back();
  };

  render() {
    const { handleSubmit } = this.props;
    const {
      warningMessage,
      showModal,
      templates,
      templateSelectedRoles,
      availSubjects,
      availSecondEntities,
    } = this.state;

    console.log("Avail second entities are ", availSecondEntities);

    return (
      <Formik
        initialValues={{
          name: "",
          massEmailIssue: "",
          workflowRequired: "",
          templateId: "",
          entityType: "",
          entityId: "",
          secondEntityId: "",
          desc: "",
          dueDate: "",
          requiredDocs: "",
          userInstructionDocs: "",
          selectedSubjectIds: [],
        }}
        enableReinitialize={true}
        onSubmit={this.onSubmit}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Enter a name"),
          desc: Yup.string().required("Please enter a description"),
          dueDate: Yup.date().required("Enter a date"),
          requiredDocs: Yup.array()
            .required("Select at least one document type")
            .test(
              "required-docs-test",
              "Please select a document",
              function (value) {
                return value && value.length > 0;
              }
            ),
          entityType: Yup.string()
            .required("Entity type is required")
            .test(
              "entity-type-test",
              "Please select an entity type",
              function (value) {
                return value && value !== "";
              }
            ),
          entityId: Yup.string()
            .required("Entity is required")
            .test("entity-test", "Please select an entity", function (value) {
              return value && value !== "";
            }),
          secondEntityId: Yup.string().test(
            "second-entity-id-test",
            "Please select an approver",
            function (value) {
              if (
                (this.parent.templateId === "" ||
                  !this.parent.templateId ||
                  templateSelectedRoles.length < 2) &&
                (!this.parent.workflowRequired ||
                  this.parent.workflowRequired === false ||
                  this.parent.workflowRequired.toUpperCase() !== "YES")
              ) {
                return true;
              }
              console.log("value for secondEntityId is ", value);
              return value && value !== "";
            }
          ),
          selectedSubjectIds: Yup.array().test(
            "subject-selection-test",
            "Please select at lease one subject",
            function (value) {
              if (this.parent.entityType === "SUBJECT") {
                return true;
              }
              return value && value.length > 0;
            }
          ),
        })}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          touched,
          resetForm,
          errors,
          handleBlur,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {/* <h3>New Task</h3> */}
            <Card>
              <Card.Header>New Task Creation Form</Card.Header>
              <Card.Body>
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId={"name"}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name={"name"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter the name"}
                      isInvalid={touched.name && !!errors.name}
                      value={values.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId={"dueDate"}>
                    <Form.Label>Due Date</Form.Label>
                    <Field
                      id="dueDate"
                      name="dueDate"
                      component={FormikDatePickerComponent}
                      placeholder={"Due date"}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId={"requiredDocs"}>
                    <Form.Label>Required Documents</Form.Label>
                    <Field
                      id="requiredDocs"
                      name="requiredDocs"
                      component={FormikFieldDualListBox}
                      options={this.state.availSubjectDocTypes}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.requiredDocs}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId={"desc"}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name={"desc"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={"Enter Description"}
                      isInvalid={touched.desc && !!errors.desc}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.desc}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId={"massEmailIssue"}>
                    <Form.Label>Is it bulk email notification?</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      name={"massEmailIssue"}
                      onChange={(value, e) => {
                        this.onMassEmailChange(value);
                        handleChange(value);
                      }}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.massEmailIssue && !!errors.massEmailIssue
                      }
                      value={values.massEmailIssue}
                    >
                      {CommonUtils.getOptionsForSelect(
                        this.state.massEmailOptions
                      )}
                    </Form.Control>
                  </Form.Group>
                  {!this.state.massEmail && (
                    <Form.Group as={Col} md="3" controlId={"workflowRequired"}>
                      <Form.Label>Is workflow needed?</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        name={"workflowRequired"}
                        onChange={(value, e) => {
                          this.onWorkflowOptionChange(value, setFieldValue);
                          handleChange(value);
                        }}
                        disabled={values.templateId}
                        onBlur={handleBlur}
                        isInvalid={
                          touched.workflowRequired && !!errors.workflowRequired
                        }
                        value={values.workflowRequired}
                      >
                        {CommonUtils.getOptionsForSelect(
                          this.state.workflowOptions
                        )}
                      </Form.Control>
                    </Form.Group>
                  )}
                  {!this.state.massEmail && this.state.templates && (
                    <Form.Group as={Col} md="3" controlId={"templateId"}>
                      <Form.Label>
                        Document Template (If signing is needed)
                      </Form.Label>
                      <Form.Control
                        required
                        as="select"
                        name={"templateId"}
                        onChange={(value, e) => {
                          this.onTemplateChange(value, setFieldValue);
                          handleChange(value);
                        }}
                        disabled={
                          values.workflowRequired &&
                          (values.workflowRequired === true ||
                            values.workflowRequired.toUpperCase() === "YES")
                        }
                        onBlur={handleBlur}
                        isInvalid={touched.templateId && !!errors.templateId}
                        value={values.templateId}
                      >
                        {CommonUtils.getOptionsForSelect(this.state.templates)}
                      </Form.Control>
                    </Form.Group>
                  )}

                  <Form.Group as={Col} md="3" controlId={"entityType"}>
                    <Form.Label>Applies To</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      name={"entityType"}
                      onChange={(value, e) => {
                        this.onEntityTypeChange(value, setFieldValue);
                        handleChange(value);
                      }}
                      onBlur={handleBlur}
                      isInvalid={!!errors.entityType || !values.entityType}
                      value={values.entityType}
                    >
                      {CommonUtils.getOptionsForSelect(
                        this.state.selectableEntityTypes
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.entityType}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId={"entityId"}>
                    <Form.Label>
                      {!values.templateId ? "Assignee" : "Signer"}{" "}
                      {values.entityType === "ORG"
                        ? "(Everyone in the Organization)"
                        : ""}
                    </Form.Label>
                    <Form.Control
                      required
                      as="select"
                      name={"entityId"}
                      disabled={values.entityType === "ORG"}
                      onChange={(value, e) => {
                        console.log("Setting second signature options");
                        this.secondSigOptions(
                          value,
                          values.templateId,
                          values.entityType
                        );
                        handleChange(value);
                      }}
                      onBlur={handleBlur}
                      isInvalid={
                        !!errors.entityId ||
                        (values.entityType !== "ORG" &&
                          (!values.entityId || !values.entityId))
                      }
                    >
                      {CommonUtils.getOptionsForSelect(
                        this.state.availEntities
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.entityId}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {(this.state.templateSelectedRoles.length === 2 ||
                    (values.workflowRequired &&
                      (values.workflowRequired === true ||
                        values.workflowRequired.toUpperCase() === "YES"))) && (
                    <Form.Group
                      as={Col}
                      sm="4"
                      md="3"
                      controlId={"secondEntityId"}
                    >
                      <Form.Label>Approver</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        name={"secondEntityId"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          !!errors.secondEntityId ||
                          !values.secondEntityId ||
                          !values.secondEntityId
                        }
                      >
                        {CommonUtils.getOptionsForSelect(
                          this.state.availSecondEntities
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.secondEntityId}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Form.Row>
                {availSubjects && availSubjects.length > 0 && (
                  <Form.Row>
                    <Form.Group as={Col} md="12">
                      <Form.Label>Select Subjects</Form.Label>
                      <Field
                        id="selectedSubjectIds"
                        name="selectedSubjectIds"
                        component={FormikFieldDualListBox}
                        options={this.state.availSubjects}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.selectedSubjectIds}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                )}
                <Form.Row>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId={"userInstructionDocs"}
                  >
                    <Form.Label>User Instruction Documents</Form.Label>
                    <Field
                      name="userInstructionDocs"
                      component={FormikFieldDropzone}
                      multiple={true}
                    />
                  </Form.Group>
                </Form.Row>
                <Fragment>
                  <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                      <Modal.Title>{"Task creation confirmation"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>{warningMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        onClick={() => {
                          resetForm();
                          this.close();
                        }}
                        variant={"danger"}
                      >
                        No
                      </Button>
                      <Button onClick={this.onConfirm} variant={"primary"}>
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <ButtonToolbar>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                    {/* <Link to="/org/dash/task" className="btn btn-danger">Cancel</Link> */}
                    <DeleteConfirmationModal
                      buttonText={"Cancel"}
                      actionText={"cancel"}
                      deleteEntity="New Task Creation Form"
                      handleDeleteCallback={this.handleCancel}
                    />
                  </ButtonToolbar>
                </Fragment>
              </Card.Body>
            </Card>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(null, {
  createEmailTask,
  createEmailTaskPrecheck,
  showSuccessGrowl,
  showErrorGrowl,
})(TaskNew);
