import React from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import {DateUtils} from "../../../../actions/common_utils";
import {Card} from "react-bootstrap";
import {approveTask, fetchReportableActivityAsync, fetchTaskAsync, rejectTask} from "../../../../actions/task_actions";
import {downloadDocument, fetchDocumentsUploadedForTask} from "../../../../actions/doc_actions";
import {SubjectReportedOnByOthersDetails} from "../subject/details/subject_reported_on_by_others";

class SubjectOtherReportsReviewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.state ={

        }
    }

    componentDidMount() {
        this.fetchTaskAsync(this.props.match.params.id);
    }

    fetchTaskAsync = async (id) => {
        const task = await fetchTaskAsync(id);
        this.setState({
            task
        })
    }

    fetchReportableAsync = async (id) => {
        const task = await fetchReportableActivityAsync(id);
        this.setState({
            reportableTask: task
        })
    }
    async onSubmit(values) {
        if (values.rejectionReason) {
            await this.props.rejectTask({rejectionReason: values.rejectionReason}, this.props.match.params.id, () => {

                // Success Response
                this.props.showSuccessGrowl("Report Rejected", "Report was rejected and subject record not affected");
                this.props.history.push("/org/dash/task");
            }, (error) => {
                console.log('Error rejecting task ', error);
                this.props.showErrorGrowl("Unexpected Error", "Cannot reject the task. Unexpected error.");
            });
        } else {
            await this.props.approveTask(this.props.match.params.id, () => {

                // Success Response
                this.props.showSuccessGrowl("Report Approved", "Report by others was approved and subject record updated");
                this.setState({
                    showModal: false
                });
                this.props.history.push("/org/dash/task");
            }, (error) => {
                console.log('Error approving task ', error);
                this.props.showErrorGrowl("Unexpected Error", "Cannot approve the task. Unexpected error.");
            });
        }
    }

    getColumns() {
        return [{
            dataField: "name",
            text: "Document Name",
            formatter: (cell, row) => cell,
        }, {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => row.type ? (row.type.id === "OTHER" ? "Other: " + row.otherType : row.type.type) : "",
        }, {
            dataField: "dateUploaded",
            text: "Uploaded Date",
            formatter: (cell, row) => DateUtils.getDateString(cell),
        }, {
            dataField: "comments",
            text: "Comments",
            formatter: (cell, row) => cell,
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar style={{flexWrap: "nowrap"}}>
                    <Button variant={'link'} className="close" onClick={() => downloadDocument(row.awsS3ObjectKey)}>
                        <span aria-hidden="true" title={"Download Document"} style={{padding: 10}} aria-label="Download Document">
                            <i className="fa fa-circle-down"/>
                        </span>
                    </Button>
                </ButtonToolbar>
            )
        }]
    }

    render() {

        let {reportableTask, task} = this.state;
        if (!task) {
            return <div>Loading...</div>;
        }
        if (!reportableTask && task.reportableActivityId){
            this.fetchReportableAsync(task.reportableActivityId);
        }
        console.log('Reportable task got is ', reportableTask)
        task.rejectionReason = '';
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>{(task.dateCompleted === null)?'Task Review':'Task Detail'}</Card.Header>
                    <Formik
                        initialValues={task}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({})
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card.Body>
                                    {reportableTask &&
                                    <Form.Row>
                                        <SubjectReportedOnByOthersDetails
                                            reportedByOthersReportables={[reportableTask]}/>
                                    </Form.Row>
                                    }
                                    {(task.dateCompleted === null) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="rejectionReason">
                                            <Form.Label>Rejection Reason</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="rejectionReason"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter a rejection reason if rejecting"
                                                isInvalid={touched.rejectionReason && !!errors.rejectionReason}
                                                value={values.rejectionReason}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rejectionReason}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                {(task.dateCompleted === null) &&
                                <Card.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit"
                                                disabled={isSubmitting || values.rejectionReason}>Approve</Button>
                                        <Button variant="danger" type="submit"
                                                disabled={isSubmitting || !values.rejectionReason}>Reject</Button>
                                    </ButtonToolbar>
                                </Card.Footer>
                                }
                            </Form>
                        )}
                    </Formik>
                </Card>
            </React.Fragment>
        )
    }
}

export const
    SubjectTaskReportByOthersReview = connect(null, {
        approveTask,
        rejectTask,
        showSuccessGrowl,
        showErrorGrowl,
        fetchDocumentsUploadedForTask
    })(SubjectOtherReportsReviewComponent);

