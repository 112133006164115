/**
 * Created by pshivaraman on 6/18/19.
 */

import React, {Component, Fragment} from "react";
import {fetchCurOrg} from "../../../../actions/org_actions";
import BreadcrumbLink from "../../../../components/bootstrap_addons/breadcrumb_link";
import {connect} from "react-redux";
import {
    cancelTask,
    completeTask,
    resendPostTravelQuestionnaireReminder,
    fetchReportableActivity,
    fetchTaskChildren,
    resetTaskEmailStatus, fetchTaskAsync
} from "../../../../actions/task_actions";
import {showSuccessGrowl} from "../../../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import {Link} from "react-router-dom";
import {DateUtils} from "../../../../actions/common_utils";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {ConditionalDisplay, isReadOnlyRole} from "../../../util/shouldDisplay";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import {ForeignTravelDetailComponent} from "../../../../components/subject/foreign_travel_detail_component";
import {SubjectForeignContactListDetails, getForeignContactWithQuesCard} from "../subject/details/subject_foreign_contact_ques_details";
import {TaskUpdate} from './task_update';
import { SubjectForeignCitizenshipDetails } from "../subject/details/subject_foreign_citizenship_details";
import CollapsibleCard from "../../../../components/bootstrap_addons/collapsible-card";
import { SubjectFinancialIssueAnomaliesDetails } from "../subject/details/subject_financial_anomalies_details";
import { SubjectAlcoholDrugTreatmentDetails } from "../subject/details/subject_alcohol_drug_treatment_details";
import { SubjectMediaContactDetails } from "../subject/details/subject_media_contact_details";
import { SubjectCohabitantDetails } from "../subject/details/subject_cohabitant_details";
import { SubjectForeignIntelligenceContactDetails } from "../subject/details/subject_foreign_intelligence_contact_details";
import { SubjectForeignAdoptionDetails } from "../subject/details/subject_foreign_adoption_details";
import { SubjectForeignPropertyDetails } from "../subject/details/subject_foreign_property_details";
import { SubjectForeignBankDetails } from "../subject/details/subject_foreign_bank_details";
import { SubjectForeignVotingDetails } from "../subject/details/subject_foreign_voting_details";
import { SubjectArrestDetails } from "../subject/details/subject_arrest_details";
import { SubjectMaritalDetails } from "../subject/details/subject_marital_details.js"
class TaskDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        this.fetchTaskAsync(this.props.match.params.id);
        this.props.fetchTaskChildren(this.props.match.params.id);
    }
    componentWillUnmount() {
        console.log('Inside component unmount')
        this.setState({
            reportableActivity: null
        })
    }

    fetchTaskAsync = async (id) => {
        const task = await fetchTaskAsync(id);
        this.getReportableActivity(task)
    }

    getReportableActivity = async (task) =>{
        console.log('Fetching reportable activity', task)
        if (task.reportableActivityId) {
            const reportableActivity = await fetchReportableActivity(task.reportableActivityId);
            this.setState({
                reportableActivity,
                task
            });
        } else {
            this.setState({
                task
            });
        }
    }

    getColumns() {
        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        }

        const customDateCompletedFilter = (filterVal, data) => {
            const lowerCaseFilterVal = filterVal.toLowerCase();
            return data.filter(row => {
                const formattedDate = row.dateCompleted ? DateUtils.getDateTimeString(row.dateCompleted).toLowerCase() : "not completed";
                return formattedDate.includes(lowerCaseFilterVal);
            });
        };
        const customEmailStatusFilter = (filterVal, data) => {
            const lowerCaseFilterVal = filterVal.toLowerCase();
            return data.filter(row => 
                row.emailStatus && row.emailStatus.displayName &&
                row.emailStatus.displayName.toLowerCase().includes(lowerCaseFilterVal)
            );
        };
        
        const emailStatusSortFunc = (a, b, order) => {
        
            const aValue = a && a.displayName ? a.displayName.toLowerCase() : '';
            const bValue = b && b.displayName ? b.displayName.toLowerCase() : '';
            
            if (order === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        };
        
        return [{
            dataField: 'personFirstName',
            text: 'First Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.subjectId}`}>{cell}</Link>,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.subjectId}`}>{cell}</Link>,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'dateCompleted',
            text: 'Date Completed',
            formatter: (cell, row) => cell ? DateUtils.getDateTimeString(cell) : "Not Completed",
            sort: true,
            filter: textFilter({
                onFilter: customDateCompletedFilter
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: 'emailStatus',
            text: 'Email Status',
            formatter: (cell, row) => cell && cell.displayName,
            sort: true,
            sortFunc: emailStatusSortFunc,
            filter: textFilter({
                onFilter: customEmailStatusFilter
            }),
            headerFormatter: headerFormatter,
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell, row) => {
                return (
                    <Fragment>
                        <ButtonToolbar>
                            {!row.dateCompleted && !isReadOnlyRole() &&
                            <Button variant="outline-info"
                                    onClick={() => this.props.resetTaskEmailStatus(row.issueId, () => {
                                        this.fetchTaskAsync(this.props.match.params.id);
                                        this.props.fetchTaskChildren(this.props.match.params.id);
                                        this.props.showSuccessGrowl("Email Status Changed", `The email will be resent shortly for ${row.personFirstName} ${row.personLastName}.`);
                                    })}>
                                <i className="fa fa-repeat"/> Resend Email
                            </Button>
                            }
                        </ButtonToolbar>
                    </Fragment>
                )
            }
        }];
    }

    getColumnsForEmailValidation() {
        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        }

        return [{
            dataField: 'personLastName',
            text: 'Last Name',
            formatter: (cell, row) => <Link to={`/org/dash/subject/${row.subjectId}`}>{cell}</Link>,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'dateCompleted',
            text: 'Date Completed',
            formatter: (cell, row) => cell ? DateUtils.getDateTimeString(cell) : "Not Completed",
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: 'emailValidationStatus',
            text: 'Validation Status',
            formatter: (cell, row) => cell && cell.displayName,
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }];
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/org/dash" onClick={() => fetchCurOrg()}>
    //                 Organization {this.props.cur_org.name}
    //             </BreadcrumbLink>
    //             <BreadcrumbLink to="/org/dash/task">
    //                 Tasks
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 {this.state.task.name}
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    getEmailStatus = (task) => {
        if (task.emailStatus) {
            return task.emailStatus.displayName;
        } else if (task.emailValidationStatus){
            return task.emailValidationStatus.displayName;
        } else {
            return "None";
        }
    }

    renderSubjectIssue(task) {
        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}

                <h3>{task.name}</h3>
                <Fragment>
                    {ConditionalDisplay(
                        <div className={'pull-right'}>
                            <ButtonToolbar>
                                <DeleteConfirmationModal buttonText={"Complete"} actionText={"mark as complete"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleComplete} buttonType={"primary"}/>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleCancel}/>
                                {!task.dateCompleted && !isReadOnlyRole() &&
                                <Button className={this.getEmailStatus(task) === "Error: No Email Address" ? 'btn btn-primary btn-disable':''} variant="primary"
                                title={this.getEmailStatus(task) === "Error: No Email Address" ? "Not a valid email" : ""}
                                onClick={() => {
                                    if(this.getEmailStatus(task) !== "Error: No Email Address"){
                                    this.props.resetTaskEmailStatus(this.props.match.params.id, () => {
                                            this.fetchTaskAsync(this.props.match.params.id);
                                            this.props.fetchTaskChildren(this.props.match.params.id);
                                            this.props.showSuccessGrowl("Email Status Changed", "The email will be resent shortly");
                                    })
                                        }}}>
                                    <i className="fa fa-repeat"/> Resend Email
                                </Button>
                                }
                            </ButtonToolbar>
                        </div>
                    )}
                </Fragment>
                <p>{task.description}</p>
                <p>Completed On: {task.dateCompleted ? DateUtils.getDateString(task.dateCompleted) : "Not Completed"}</p>
                <p>Email Status: {this.getEmailStatus(task)}</p>
            </Fragment>
        )
    }

    renderEmailIssue(task, tasks){
        return (
            <Fragment>
                {/* {this.getBreadCrumb()} */}

                <h3>{task.name}</h3>
                <Fragment>
                    {ConditionalDisplay(
                        <div className={'pull-right'}>
                            <ButtonToolbar>
                                <DeleteConfirmationModal buttonText={"Complete"} actionText={"mark as complete"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleComplete} buttonType={"primary"}/>
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleCancel}/>
                            </ButtonToolbar>
                        </div>
                    )}
                </Fragment>
                <p>{task.description}</p>
                <p>Completed On: {task.dateCompleted ? DateUtils.getDateString(task.dateCompleted) : "Not Completed"}</p>

                <h6>Percent Completed</h6>
                <ProgressBar now={task.percentComplete}/>
                <br/>
                <Alert key={'progressBarAlertKey'} variant={'warning'}>
                    Progress bar status may be out of sync by a few minutes as it is updated by a timer!
                </Alert>

                <h5>Subjects</h5>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="issueId" data={tasks ? tasks : []}
                                columns={this.getColumnsForEmailValidation()} filter={filterFactory()}/>
            </Fragment>
        )
    }

    handleCancel = () => {
        this.props.cancelTask(this.props.match.params.id, ()=>{
            this.props.history.push('/org/dash/task');
        });
    }

    handleComplete = () => {
        this.props.completeTask(this.props.match.params.id, () => {
            this.props.history.push('/org/dash/task');
        });
    }

    handleEmailResend = () => {
        const {task} = this.state;
        this.props.resendPostTravelQuestionnaireReminder(task.reportableActivityId, () => {
            this.props.history.push('/org/dash/task');
        });
    }

    render() {
        const {tasks} = this.props;
        const {reportableActivity, task} = this.state;

        console.log('Reportable Activity is ', reportableActivity)
        if (!task) {
            return <div>Loading...</div>;
        }

        if (task.reportableActivityId && !reportableActivity) {
            return <div>Loading...</div>;
        }

        let displayReportableActivity = false;
        if (reportableActivity && (reportableActivity.id === task.reportableActivityId)){
            displayReportableActivity = true;
        }
        // Handle Subject Issues
        if (task.entityType && task.entityType.id === "SUBJECT") {
            return this.renderSubjectIssue(task);
        }

        // Handle Email Issues
        if (task.issueType.id === "SUBJECT_EMAIL_VALIDATE"){
            return this.renderEmailIssue(task, tasks);
        }

        let childTasks = tasks;

        if (childTasks == null) {
            childTasks = [];
        }

        return (
            <div>
                {/* {this.getBreadCrumb()} */}

                <h3>{task.name}</h3>
                <Fragment>
                    {ConditionalDisplay(
                        <div className={'pull-right'}>
                            <ButtonToolbar>
                                {!task.reportableActivityId && task.requiredDocs && task.requiredDocs.length === 1 &&
                                    <TaskUpdate task={task} onFinish={this.componentDidMount}/>
                                }
                                {!task.reportableActivityId &&
                                <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleCancel}/>
                                }
                                {task.reportableActivityId &&
                                <DeleteConfirmationModal buttonText={"Complete"} actionText={"mark as complete"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleComplete} buttonType={"primary"}/>
                                }
                                {task.reportableActivityId && task.description.includes('post travel questionnaire') &&
                                <DeleteConfirmationModal buttonText={"Resend Email"} actionText={"Resend the email to subject"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleEmailResend} buttonType={"primary"}/>
                                }
                            </ButtonToolbar>
                        </div>
                    )}
                </Fragment>

                <p>{task.description}</p>

                {!task.reportableActivityId &&
                <Fragment>
                    <h6>Percent Completed</h6>
                    <ProgressBar now={task.percentComplete}/>
                    <br/>
                    <Alert key={'progressBarAlertKey'} variant={'warning'}>
                        Progress bar status may be out of sync by a few minutes as it is updated by a timer!
                    </Alert>

                    <p>{task.status}</p>

                    <h5>Subjects</h5>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="issueId" data={childTasks}
                                    columns={this.getColumns()} filter={filterFactory()}/>
                </Fragment>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'FOREIGN_TRAVEL' &&
                    <Fragment>
                        <ForeignTravelDetailComponent foreignTravelDetail={reportableActivity}/>
                    </Fragment>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'FOREIGN_CONTACTS_QUESTIONNAIRE' &&
                    <Fragment>
                        <CollapsibleCard header={'Foreign Contact Details'} defaultOpen={true}>
                            <SubjectForeignContactListDetails contactReportables={[reportableActivity]} />
                        </CollapsibleCard>
                    </Fragment>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'FOREIGN_CITIZENSHIP' &&
                    <CollapsibleCard header="Foreign Citizenships" defaultOpen={true}>
                        <SubjectForeignCitizenshipDetails foreignCitizenships={[reportableActivity]} />
                    </CollapsibleCard>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'FINANCIAL_ISSUES_AND_ANOMALIES' &&
                    <CollapsibleCard header="Financial Issues & Anomalies" defaultOpen={true}>
                        <SubjectFinancialIssueAnomaliesDetails financialIssuesAndAnomalies={[reportableActivity]} />
                    </CollapsibleCard>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'ALCOHOL_DRUG_TREATMENT' &&
                    <CollapsibleCard header={"Alcohol/Drug Treatments"} defaultOpen={true}>
                        <SubjectAlcoholDrugTreatmentDetails alcoholDrugTreatmentReportables={[reportableActivity]} />
                    </CollapsibleCard>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'MEDIA_CONTACT' &&
                    <CollapsibleCard header="Media Contacts" defaultOpen={true}>
                        <SubjectMediaContactDetails mediaContacts={[reportableActivity]} />
                    </CollapsibleCard>
                }
                 {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'PERSONNEL_INFO_MARITAL_STATUS' &&
                     <CollapsibleCard header="Marital Status" defaultOpen={true}>
                         <SubjectMaritalDetails spouseHistory={[reportableActivity]} isTaskDetail={true} />
                     </CollapsibleCard>
                 }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'COHABITANT' &&
                    <CollapsibleCard header="Cohabitants" defaultOpen={true}>
                        <SubjectCohabitantDetails cohabitants={[reportableActivity]} />
                    </CollapsibleCard>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'FOREIGN_INTELLIGENCE_ENTITY_CONTACT' &&
                    <CollapsibleCard header="Foreign Intelligence Contacts" defaultOpen={true}>
                        <SubjectForeignIntelligenceContactDetails foreignIntelligenceEntities={[reportableActivity]} />
                    </CollapsibleCard>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'ADOPTION_OF_NON_US_CHILDREN' &&
                    <CollapsibleCard header="Foreign Adoptions" defaultOpen={true}>
                        <SubjectForeignAdoptionDetails foreignChildAdoptions={[reportableActivity]} />
                    </CollapsibleCard>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'FOREIGN_PROPERTY_OWNERSHIP' &&
                    <CollapsibleCard header="Foreign Properties" defaultOpen={true}>
                        <SubjectForeignPropertyDetails  foreignProperties={[reportableActivity]} />
                    </CollapsibleCard>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'FOREIGN_BANK_ACCOUNT' &&
                    <CollapsibleCard header="Foreign Banks" defaultOpen={true}>
                        <SubjectForeignBankDetails foreignBankAccts={[reportableActivity]} />
                    </CollapsibleCard>
                }
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'FOREIGN_ELECTION_VOTING' &&
                    <CollapsibleCard header="Foreign Voting Details" defaultOpen={true}>
                        <SubjectForeignVotingDetails foreignVotings={[reportableActivity]} />
                    </CollapsibleCard>
                } 
                {task.reportableActivityId && displayReportableActivity && reportableActivity.type === 'ARRESTS' &&
                    <CollapsibleCard header="Arrests" defaultOpen={true}>
                        <SubjectArrestDetails arrests={[reportableActivity]} />
                    </CollapsibleCard>
                }  
            </div>
        );
    }
}

function mapStateToProps({task, tasks, reportableActivity}) {
    return {
        task,
        tasks
    }
}

export default connect(mapStateToProps, {fetchTaskChildren, showSuccessGrowl, completeTask, resendPostTravelQuestionnaireReminder, cancelTask, resetTaskEmailStatus})(TaskDetail);