import React from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import {DateUtils} from "../../../../actions/common_utils";
import {Card} from "react-bootstrap";
import {approveTask, fetchTask, rejectTask , cancelTask} from "../../../../actions/task_actions";
import {downloadDocument, fetchDocumentsUploadedForTask} from "../../../../actions/doc_actions";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";

class SubjectTaskReviewComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        console.log('ID passed is ', this.props.match.params.id)
        this.props.fetchTask(this.props.match.params.id);
        this.props.fetchDocumentsUploadedForTask(this.props.match.params.id);
    }

    async onSubmit(values) {
        console.log('Visit is ', values);
        if (values.rejectionReason) {
            await this.props.rejectTask({rejectionReason: values.rejectionReason}, this.props.match.params.id, () => {

                // Success Response
                this.props.showSuccessGrowl("Task Rejected", "Task was rejected and subject notified");
                this.props.history.push("/org/dash/task");
            }, (error) => {
                console.log('Error rejecting task ', error);
                this.props.showErrorGrowl("Unexpected Error", "Cannot reject the task. Unexpected error.");
            });
        } else {
            await this.props.approveTask(this.props.match.params.id, () => {

                // Success Response
                this.props.showSuccessGrowl("Task Approved", "Task was approved successfully");
                this.setState({
                    showModal: false
                });
                this.props.history.push("/org/dash/task");
            }, (error) => {
                console.log('Error approving task ', error);
                this.props.showErrorGrowl("Unexpected Error", "Cannot approve the task. Unexpected error.");
            });
        }
    }

    getColumns() {
        return [{
            dataField: "name",
            text: "Document Name",
            formatter: (cell, row) => cell,
        }, {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => row.type ? (row.type.id === "OTHER" ? "Other: " + row.otherType : row.type.type) : "",
        }, {
            dataField: "dateUploaded",
            text: "Uploaded Date",
            formatter: (cell, row) => DateUtils.getDateString(cell),
        }, {
            dataField: "comments",
            text: "Comments",
            formatter: (cell, row) => cell,
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar style={{flexWrap: "nowrap"}}>
                    <Button variant={'link'} className="close" onClick={() => downloadDocument(row.awsS3ObjectKey)}>
                        <span aria-hidden="true" title={"Download Document"} style={{padding: 10}} aria-label="Download Document">
                            <i className="fa fa-circle-down"/>
                        </span>
                    </Button>
                </ButtonToolbar>
            )
        }]
    }

    getDocumentsRequired = (requiredDocs) => {
        let retStr = '';
        if (requiredDocs){
            requiredDocs.forEach((requiredDoc)=>{
                retStr = requiredDoc.type + ', ';
            })
            return retStr.substr(0, retStr.length-2);
        }
        return '';
    }
    handleCancel = () => {
        this.props.cancelTask(this.props.match.params.id, ()=>{
            this.props.history.push('/org/dash/task');
        });
    }

    render() {

        let {task, docs} = this.props;
        if (!task) {
            return <div>Loading...</div>;
        }

        task.rejectionReason = '';
        console.log('Task is ', task);
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>{(task.dateCompleted === null)?'Task Review':'Task Detail'}</Card.Header>
                    <Formik
                        initialValues={task}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({})
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6" controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.name && !!errors.name}
                                                value={values.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="description"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.description && !!errors.description}
                                                value={values.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="emailStatus">
                                            <Form.Label>Email Status</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="emailStatus"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.emailStatus && !!errors.emailStatus}
                                                value={values.emailStatus?values.emailStatus.displayName:"None"}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.emailStatus}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="requiredDocs">
                                            <Form.Label>Documents Required</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="requiredDocs"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.requiredDocs && !!errors.requiredDocs}
                                                value={this.getDocumentsRequired(values.requiredDocs)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.requiredDocs}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="docSubmitWorkflowStatus">
                                            <Form.Label>Workflow Status</Form.Label>
                                            <Form.Control
                                                required
                                                type={'text'}
                                                name="docSubmitWorkflowStatus"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.docSubmitWorkflowStatus && !!errors.docSubmitWorkflowStatus}
                                                value={values.docSubmitWorkflowStatus?values.docSubmitWorkflowStatus.displayName:''}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.docSubmitWorkflowStatus}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    
                                    
                                    {docs && docs.length > 0 &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="docs">
                                        <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                                                        data={docs}
                                                        columns={this.getColumns()} filter={filterFactory()}
                                        />
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                    {(task.dateCompleted === null) &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="rejectionReason">
                                            <Form.Label>Rejection Reason</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="rejectionReason"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter a rejection reason if rejecting"
                                                isInvalid={touched.rejectionReason && !!errors.rejectionReason}
                                                value={values.rejectionReason}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rejectionReason}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                {(task.dateCompleted === null) &&
                                <Card.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit"
                                                disabled={isSubmitting || values.rejectionReason}>Approve</Button>
                                        <Button variant="danger" type="submit"
                                                disabled={isSubmitting || !values.rejectionReason}>Reject</Button>
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>
                                </Card.Footer>
                                }
                            </Form>
                        )}
                    </Formik>
                </Card>
            </React.Fragment>
        )
    }
}

function mapStateToProps({task, docs}) {
    return {
        task,
        docs: docs.data
    }
}

export const
    SubjectTaskReview = connect(mapStateToProps, {
        approveTask,
        rejectTask,
        showSuccessGrowl,
        showErrorGrowl,
        fetchTask,
        fetchDocumentsUploadedForTask,
        cancelTask,
    })(SubjectTaskReviewComponent);

