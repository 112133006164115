import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { FieldMultiSelectDropdown } from "../../../../components/redux_form/field_components_rb";
import { Picky } from 'react-picky';
import { FormikFieldMultiSelectDropDown } from "../../../../components/formik/formik_multiselectdropdown";




export const Task_Filter = ({ filters, onSubmit }) => {

    const [ShowHover, setShowHover] = useState(false);
    const setFilterValue = (filter, defaultVal = "") => filter ? filter.filterVal : defaultVal;
    const setSelectFilterValue = (filter, defaultVal = []) => filter ? filter.filterVal.map((val) => val.value) : defaultVal;
    const popupRef = useRef(null);

    useEffect(()=>{
        const handleClickOutside = (event) => { 
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        }; 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[])


    const OnSubmitClick = (values) => {
        onSubmit(values, false)
        setShowHover(false)
    }

    const OnResetClick = (values) => {
        onSubmit(values, true)
        setShowHover(false)
    }

    return (
        <>
             <div className="dropdown filter_menu" ref={popupRef}>
                <div className="btn" onClick={() => { setShowHover(true) }}>
                    <i className="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div className="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={filters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>

                                <div className="col-lg-12">
                                    <div className="form-group mb-3">
                                        <Form.Check
                                            type="checkbox"
                                            name={"showManagedOrgsFilter"}
                                            onBlur={handleBlur}
                                            label="Include Managed Orgs?"
                                            onChange={handleChange}
                                            onClick={() => values.includeSubOrgs = !values.includeSubOrgs}
                                        />
                                    </div>



                                    <div className="form-group mb-3">
                                        <label className="form-label col-form-label">Entity Type</label>
                                        <Field
                                            name="entityTypesFilter"
                                            component={FormikFieldMultiSelectDropDown}
                                            value={values.entityTypesFilter}
                                            // onChange={(e) => { console.log(e) }}
                                            options={[
                                                { value: 'ORG', label: 'Organization' },
                                                { value: 'FACILITY', label: 'Location' },
                                                { value: 'CONTRACT', label: 'Contract' },
                                                { value: 'SUBJECT', label: 'Subject' }
                                            ]}
                                            multiple={true}
                                            placeholder="Select options"
                                        />
                                    </div>



                                    <div className="form-group mb-3">
                                    <label className="form-label col-form-label">Workflow Status</label>
                                        <Field
                                            name="docSubmitWorkflowStatus"
                                            component={FormikFieldMultiSelectDropDown}
                                            value={values.docSubmitWorkflowStatus}
                                           
                                            options={[
                                                { value: 'PENDING', label: 'Pending' },
                                                { value: 'SUBJECT_NOTIFIED', label: 'Subject Notified' },
                                                { value: 'DOCS_SUBMITTED', label: 'Documents Submitted' },
                                                { value: 'REJECTED', label: 'Rejected' },
                                                { value: 'APPROVED', label: 'Approved' },
                                                { value: "CLEARANCE_CHECK_COMPLETED", label: "Clearance Check Completed"}
                                            ]}
                                            multiple={true}
                                            placeholder="Select options"
                                        />
                                    </div>

                                    <div className="form-group mb-3">
                                    <label className="form-label col-form-label">Status</label>
                                        <Field
                                            name="issueStatusFilter"
                                            component={FormikFieldMultiSelectDropDown}
                                            value={values.issueStatusFilter}
                                            options={[
                                                { value: 'ACTIVE', label: 'Active' },
                                                { value: 'CANCELLED', label: 'Cancelled' },
                                                { value: 'COMPLETED', label: 'Completed' }
                                            ]}
                                            multiple={true}
                                            placeholder="Select options"
                                        />

                                    </div>

                                    <div className="form-group mb-3">
                                    {/* <label>Name</label> */}
                                        <Form.Control
                                            type="text"
                                            name={"issueNameFilter"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Issue Name"}
                                            value={values.issueNameFilter}
                                        />
                                    </div>

                                    <div className="form-group mb-3">
                                    {/* <label>Entity Name</label> */}
                                        <Form.Control
                                            type="text"
                                            name={"entityNameFilter"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enity Name"}
                                            value={values.entityNameFilter}
                                        />

                                    </div>

                                    <ButtonToolbar>
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick(values); 
                                        }}>Reset</button>
                                        <button type="submit" className="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}