import React from "react";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {showErrorGrowl, showSuccessGrowl} from "../../../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import {DateUtils} from "../../../../actions/common_utils";
import {Card} from "react-bootstrap";
import {approveTask, fetchTask, rejectTask , cancelTask} from "../../../../actions/task_actions";
import {downloadDocument, fetchDocumentsUploadedForTask} from "../../../../actions/doc_actions";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import DeleteConfirmationModal from "../../../../components/delete_confirm_modal";
import { FormikDatePickerComponent } from "../../../../components/formik/formik_date_picker";
import { completeClearanceCheck } from "../../../../actions/new_hire_actions"
import {  getFacilityLevels,getCitizenshipAcquiredTypes} from "../../../../actions/fieldValue_actions";
import { CommonUtils} from "../../../../actions/common_utils";

class SubjectTaskCheckClearanceComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eligibilityLevels:[]
        }

        this.onSubmit = this.onSubmit.bind(this);

    }

    componentDidMount() {
        console.log('ID passed is ', this?.props?.match?.params.id || this?.props?.task?.id)
        this.props.fetchTask(this?.props?.match?.params.id || this?.props?.task?.id);
        if(!this?.props?.isReported){
        this.props.fetchDocumentsUploadedForTask(this?.props?.match?.params.id);
        }
        getFacilityLevels((response) => {
            let newEligibilityLevels = []  
         response.data.forEach((eligibilityLevel) => {
              if (eligibilityLevel.displayName.toLowerCase() !== "user") {
                newEligibilityLevels.push({
                  id: eligibilityLevel.id,
                  name: eligibilityLevel.displayName,
                });
              }
            });
            this.setState({
                eligibilityLevels: newEligibilityLevels
            });
         })
         this.getCitizenshipTypes();
      }

    async onSubmit(values) {
        console.log(values,"values")
        if (values.entityType)
            values.entityType = values.entityType.id;
 
        if (values.issueType)
            values.issueType = values.issueType?.id;

        if (values.docSubmitWorkflowStatus)
            values.docSubmitWorkflowStatus = values.docSubmitWorkflowStatus?.id;

         if (values.subjectHireDetailsUI && values.subjectHireDetailsUI?.requiredClearanceLevel?.id) {
             values.subjectHireDetailsUI.requiredClearanceLevel = values.subjectHireDetailsUI.requiredClearanceLevel.id;
         } 
          if(values.subjectHireDetailsUI && values.subjectHireDetailsUI?.citizenshipAcquiredType?.id) {
              values.subjectHireDetailsUI.citizenshipAcquiredType = values.subjectHireDetailsUI.citizenshipAcquiredType.id;
         }else{
            values.subjectHireDetailsUI.citizenshipAcquiredType = values?.subjectHireDetailsUI?.citizenshipAcquiredType;
         }

        console.log('Task is ', values);
        try {
            const response =  await completeClearanceCheck(values);
             this.props.showSuccessGrowl("Clearance Check Complete", response.data);
             this.props.history.push("/org/dash/task");
         } catch (e) {
             console.log('Error resp is ', e);
             if (e.response && e.response.status === 500){
                 this.props.showErrorGrowl("Clearance Check Complete", e.response.data);
             } else {
                this.props.showErrorGrowl("Unexpected Error", "Cannot approve the task. Unexpected error.");
            }
         }
    }

     

    getColumns() {
        return [{
            dataField: "name",
            text: "Document Name",
            formatter: (cell, row) => cell,
        }, {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => row.type ? (row.type.id === "OTHER" ? "Other: " + row.otherType : row.type.type) : "",
        }, {
            dataField: "dateUploaded",
            text: "Uploaded Date",
            formatter: (cell, row) => DateUtils.getDateString(cell),
        }, {
            dataField: "comments",
            text: "Comments",
            formatter: (cell, row) => cell,
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar style={{flexWrap: "nowrap"}}>
                    <Button variant={'link'} className="close" onClick={() => downloadDocument(row.awsS3ObjectKey)}>
                        <span aria-hidden="true" title={"Download Document"} style={{padding: 10}} aria-label="Download Document">
                            <i className="fa fa-circle-down"/>
                        </span>
                    </Button>
                </ButtonToolbar>
            )
        }]
    }

    getDocumentsRequired = (requiredDocs) => {
        let retStr = '';
        if (requiredDocs){
            requiredDocs.forEach((requiredDoc)=>{
                retStr = requiredDoc.type + ', ';
            })
            return retStr.substr(0, retStr.length-2);
        }
        return '';
    }
    handleCancel = () => {
        this.props.cancelTask(this.props.match.params.id, ()=>{
            this.props.history.push('/org/dash/task');
        });
    }
    getCitizenshipTypes = async () => {
        const response = await getCitizenshipAcquiredTypes();
        let newCitizenshipTypes = [];
        const chosenData = this.props.task?.subjectHireDetailsUI?.citizenshipAcquiredType;
        response.data.forEach((citizenType) => {
          if (citizenType.displayName.toLowerCase() !== "user") {
            newCitizenshipTypes.push({
              id: citizenType.id,
              name: citizenType.displayName,
            });
          }
        });
        const existingId = chosenData?.id ? chosenData.id :chosenData
        // Find the chosen data item and remove it from the array
        const chosenItem = newCitizenshipTypes.find(
          (item) => item?.id === existingId
        );
      
        if (chosenItem) {
          newCitizenshipTypes = newCitizenshipTypes.filter(
            (item) => item?.id !== existingId
          );
        }
        // Sort the remaining items by id
        newCitizenshipTypes.sort((a, b) => a?.id - b?.id);
      
        if (chosenItem) {
          newCitizenshipTypes?.unshift(chosenItem);
        }   
        console.log(newCitizenshipTypes,"newCitizenshipTypes")   
        this.setState({
          citizenshipTypes: newCitizenshipTypes,
        });
      };
     

    render() {

        let {task, docs,isReported } = this.props;
        if (!task) {
            return <div>Loading...</div>;
        }
        if (task.subjectHireDetailsUI && task.subjectHireDetailsUI.citizenshipAcquiredType && task.subjectHireDetailsUI.citizenshipAcquiredType.id){
            task.subjectHireDetailsUI.citizenshipAcquiredType = task.subjectHireDetailsUI.citizenshipAcquiredType.id;
        }

        task.rejectionReason = '';
        console.log('Task is ', task);
        return (
            <React.Fragment>
                <Card>
                    <Card.Header>{!isReported && ((task.dateCompleted === null)?'Task Review':'Task Detail')}</Card.Header>
                    <Formik
                        initialValues={task}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                eligibilityDeterminationDetails: Yup.string().nullable().required('Eligibility Determination is required'),
                                investigation: Yup.string().nullable().required('Supporting Investigation is required'),
                                cvEnrollmentType: Yup.string().nullable().required('CV Enrollment Type is required'),
                                cvEnrollmentDate: Yup.string().nullable().required('CV Enrollment Date is required'),
                            })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (

                            <Form noValidate onSubmit={handleSubmit}>
                                <Card.Body>
                                    <Form.Row>

                                        <Form.Group as={Col} md="6" controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.name && !!errors.name}
                                                value={values.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="SSN">
                                            <Form.Label>SSN</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="SSN"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur} 
                                                value={values.subjectHireDetailsUI.socialSecurityNumber}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="description"
                                                disabled
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.description && !!errors.description}
                                                value={values.description}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.description}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="eligibilityLevel">
                                            <Form.Label>Eligibility Level</Form.Label>
                                            <Form.Control required as="select" name={'eligibilityLevel'}
                                                         onChange={handleChange}
                                                         onBlur={handleBlur}
                                                         disabled={isReported}
                                                         isInvalid={touched.eligibilityLevel && !!errors.eligibilityLevel}
                                                         value={values?.eligibilityLevel !== null? values?.eligibilityLevel :null}
                                                         >
                                                        {CommonUtils.getOptionsForSelect(this.state.eligibilityLevels)}
                                               </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.eligibilityLevel}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {values.subjectHireDetailsUI.requiredClearanceLevel && <Form.Group as={Col} md="6" controlId="subjectHireDetailsUI.requiredClearanceLevel">
                                                    <Form.Label>Required Clearance Level </Form.Label>
                                                    <Form.Control
                                                        required
                                                        name="subjectHireDetailsUI.requiredClearanceLevel"
                                                        onChange={handleChange}
                                                        disabled
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.requiredClearanceLevel && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.requiredClearanceLevel)}
                                                        value={values.subjectHireDetailsUI.requiredClearanceLevel? values.subjectHireDetailsUI.requiredClearanceLevel?.displayName:''}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.requiredClearanceLevel:''}
                                                    </Form.Control.Feedback>
                                                </Form.Group>}
                                        <Form.Group as={Col} md="6" controlId="eligibilityDeterminationDetails">
                                            <Form.Label>Eligibility Determination</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="eligibilityDeterminationDetails"
                                                onChange={handleChange}
                                                disabled={isReported}
                                                onBlur={handleBlur}
                                                isInvalid={touched.eligibilityDeterminationDetails && !!errors.eligibilityDeterminationDetails}
                                                value={values.eligibilityDeterminationDetails && !isReported ? values.eligibilityDeterminationDetails.displayName : isReported  ? values.eligibilityDeterminationDetails : ''}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.eligibilityDeterminationDetails}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="exceptionCode">
                                            <Form.Label>Exception Code</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="exceptionCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur} 
                                                disabled={isReported}
                                                value={values.exceptionCode}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="investigation">
                                            <Form.Label>Supporting Investigation</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="investigation"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={isReported}
                                                isInvalid={touched.investigation && !!errors.investigation}
                                                value={values.investigation}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.investigation}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="cvEnrollmentType">
                                            <Form.Label>CV Enrollment Type</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="cvEnrollmentType"
                                                onChange={handleChange}
                                                disabled={isReported}
                                                onBlur={handleBlur} 
                                                isInvalid={touched.cvEnrollmentType && !!errors.cvEnrollmentType}
                                                value={values.cvEnrollmentType && !isReported ? values.cvEnrollmentType.displayName : isReported? values.cvEnrollmentType:''}
                                            />
                                             <Form.Control.Feedback type="invalid">
                                                {errors.cvEnrollmentType}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" controlId="cvEnrollmentDate">
                                        <Form.Label>CV Effective Date</Form.Label>
                                                    <Field
                                                        id="cvEnrollmentDate"
                                                        name="cvEnrollmentDate"
                                                        component={FormikDatePickerComponent}
                                                    /> 
                                        </Form.Group> 

                                        

                                        {values.subjectHireDetailsUI.citizenshipAcquiredType &&
                                            <Form.Group as={Col} md="6" controlId={'subjectHireDetailsUI.citizenshipAcquiredType'}>
                                                <Form.Label>Citizenship Type</Form.Label>
                                                <Form.Control required as="select" name={'subjectHireDetailsUI.citizenshipAcquiredType'}
                                                         onChange={handleChange}
                                                         onBlur={handleBlur}
                                                         isInvalid={touched.subjectHireDetailsUI && touched.subjectHireDetailsUI.citizenshipAcquiredType && !!(errors.subjectHireDetailsUI && errors.subjectHireDetailsUI.citizenshipAcquiredType)}
                                                         value={values.subjectHireDetailsUI.citizenshipAcquiredType}
                                                 >
                                                        {CommonUtils.getOptionsForSelect(this.state.citizenshipTypes)}
                                               </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                {errors.subjectHireDetailsUI?errors.touched.subjectHireDetailsUI.citizenshipAcquiredType:''}
                                                </Form.Control.Feedback>
                                             </Form.Group>}
                                             {values.subjectHireDetailsUI.citizenshipDate &&
                                              <Form.Group as={Col} md="6" controlId={'citizenshipDate'}>
                                                   <Form.Label> Citizenship Date</Form.Label>
                                                       <Field
                                                         id="subjectHireDetailsUI.citizenshipDate"
                                                        name="subjectHireDetailsUI.citizenshipDate"
                                                        component={FormikDatePickerComponent}
                                                        placeholder={"citizen Ship Date"}
                                                        />
                                              </Form.Group>}
                                                    <Form.Control.Feedback type="invalid">
                                                         {errors.citizenshipDate}
                                                </Form.Control.Feedback>

                                        </Form.Row>
                                        

                                       
                                    {docs && docs.length > 0 &&
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="docs">
                                        <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                                                        data={docs}
                                                        columns={this.getColumns()} filter={filterFactory()}
                                        />
                                        </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                {(task.dateCompleted === null) && !isReported &&
                                <Card.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit"
                                                disabled={isSubmitting || values.rejectionReason}>Complete</Button>
                                        {/* <Button variant="danger" type="submit"
                                                disabled={isSubmitting || !values.rejectionReason}>Reject</Button> */}
                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Task"
                                                         handleDeleteCallback={this.handleCancel}/>
                                    </ButtonToolbar>
                                </Card.Footer>
                                }
                            </Form>
                        )}
                    </Formik>
                </Card>
            </React.Fragment>
        )
    }
}

function mapStateToProps({task, docs}) {
    return {
        task,
        docs: docs.data
    }
}

export const
SubjectTaskRCheckClearanceReview = connect(mapStateToProps, {
        approveTask,
        rejectTask,
        showSuccessGrowl,
        showErrorGrowl,
        fetchTask,
        fetchDocumentsUploadedForTask,
        cancelTask,
    })(SubjectTaskCheckClearanceComponent);

